import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { ContentContainer } from '../common/content/ContentContainer'
import { ContentLogo } from '../common/content/ContentLogo'
import { useTranslation } from 'react-i18next'
import { FormControl } from '../common/form/FormControl'
import { LoginContainer } from './LoginContainer'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoginFormDto, loginFormSchema } from './loginFormSchema'

export const Login: FC = () => {
  const { t } = useTranslation()
  const onSubmit: SubmitHandler<LoginFormDto> = (data) => console.log(data)
  const methods = useForm<LoginFormDto>({
    resolver: zodResolver(loginFormSchema),
  })

  return (
    <>
      <Helmet>
        <title>{t('Log In')}</title>
      </Helmet>

      <ContentContainer className="py-8">
        <ContentLogo />
      </ContentContainer>

      <LoginContainer>
        <h1 className="mb-2 mt-6 text-center text-3xl font-bold tracking-tight">{t('Log In')}</h1>
        <button className="mx-auto flex">{t('Login with Google')}</button>
        <div className="text-center text-gray-500">{t('or')}</div>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormControl name="email" render={({ id }) => <input type="email" id={id} placeholder={t('Email')} />} />

            <FormControl
              name="password"
              render={({ id }) => <input type="password" id={id} placeholder={t('Password')} />}
            />

            <button className="w-full rounded-lg border bg-neutral-800 px-4 py-3 font-medium text-white hover:bg-neutral-900 active:bg-neutral-950">
              {t('Log In')}
            </button>
          </form>
        </FormProvider>
      </LoginContainer>
    </>
  )
}
