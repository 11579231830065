import { Point } from '../../common/Point'
import { makeAutoObservable } from 'mobx'
import { generateMemberId } from '../functions/generateMemberId'

export class CalcDocNote {
  constructor(
    public position: Point,
    public content: string = '',
    public id = generateMemberId(), // to be able to restore id after serialization
  ) {
    makeAutoObservable(this)
  }

  setPosition(value: Point) {
    this.position = value
  }

  setContent(value: string) {
    this.content = value
  }
}
