import React, { FC, useContext } from 'react'
import { CalcContext } from './context/CalcContext'
import { observer } from 'mobx-react-lite'

export const CalcCursorSelectionView: FC = observer(() => {
  const { bbox } = useContext(CalcContext).selection.cursor

  if (bbox) {
    return (
      <div
        className="fixed z-10 border border-neutral-500 bg-neutral-500/5"
        style={{
          top: bbox[0].y,
          left: bbox[0].x,
          width: bbox[1].x - bbox[0].x,
          height: bbox[1].y - bbox[0].y,
        }}
      ></div>
    )
  }

  return null
})
