import React, { FC, useContext } from 'react'
import { CalcContext } from './context/CalcContext'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { CalcMemberExpressionInput } from './value/CalcMemberExpressionInput'

export const CalcNewExpressionMember: FC = observer(() => {
  const { doc, history, scale, newMember } = useContext(CalcContext)
  const { member } = newMember

  if (!member) {
    return null
  }

  const position = scale.calcPosition(member.position)

  return (
    <div
      className={classNames('fixed z-10 -translate-x-1/2 -translate-y-1/2 py-1')}
      style={{ top: position.y, left: position.x }}
    >
      <CalcMemberExpressionInput
        expression={member.expression}
        onCancel={() => {
          newMember.cancel()
        }}
        onFinish={(expression) => {
          if (member) {
            member.setExpression(expression)
            member.setCaptionEditing(true)

            if (doc.addMember(member)) {
              history.add({
                undo() {
                  member.setDeleted(true)
                },
                redo() {
                  member.setDeleted(false)
                },
              })

              newMember.cancel()
            }
          }
        }}
      />
    </div>
  )
})
