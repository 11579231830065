import { cloneElement, FC, ReactElement, ReactNode } from 'react'
import { useUniqueId } from '../hooks/useUniqueId'
import { clsx } from 'clsx'
import { useFormContext } from 'react-hook-form'

export const FormControl: FC<{
  name: string
  label?: ReactNode
  render: (props: { id: string }) => ReactElement<{ className: string }>
}> = ({ name, label, render }) => {
  const id = useUniqueId()
  const element = render({ id })
  const { register, getFieldState } = useFormContext()
  const { error } = getFieldState(name)

  return (
    <div className="mb-4">
      {label && (
        <label className="mb-1 inline-flex" htmlFor={id}>
          {label}
        </label>
      )}

      {cloneElement(element, {
        className: clsx(element.props.className, 'w-full px-4 py-3 bg-white rounded-md border'),
        ...register(name),
      })}

      {error ? <div className="mt-2 text-sm text-red-700">{error.message}</div> : null}
    </div>
  )
}
