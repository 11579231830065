import React, { FC, PropsWithChildren, useCallback, useContext, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { onMouseMoveUntilUp } from '../../common/functions/onMouseMoveUntilUp'
import { CalcContext } from '../context/CalcContext'
import { CalcMemberViewContext } from './CalcMemberViewContext'
import { CalcMemberCaptionView } from './CalcMemberCaptionView'
import { CalcMember } from '../doc/CalcMember'
import { isMoveThresholdOvercome } from '../../common/functions/isMoveThresholdOvercome'

import { Point } from '../../common/Point'

export const CalcMemberView: FC<
  PropsWithChildren<{
    member: CalcMember
  }>
> = observer(({ member, children }) => {
  const { history, selection, elements, intersections, scale } = useContext(CalcContext)
  const [dragging, setDragging] = useState(false)
  const ref = useCallback(
    (element: HTMLDivElement | null) => {
      element ? elements.set(member, element) : elements.delete(member)
    },
    [member, elements],
  )
  const position = scale.calcPosition(member.position)

  return (
    <CalcMemberViewContext.Provider value={{ member }}>
      <div
        className={classNames(
          'fixed z-10 flex cursor-default select-none flex-col items-center justify-center rounded bg-white px-2 py-1 text-center',
          selection.isSelected(member) && 'ring-2 ring-neutral-900',
          (dragging || member.valueEditing || member.captionEditing) && 'z-10',
        )}
        style={{
          top: position.y,
          left: position.x,
          transform: `translate(-50%, -20px)`,
        }}
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.stopPropagation()

            selection.toggle(member)
          }
        }}
        onMouseDown={(down) => {
          down.stopPropagation()

          intersections.setDraggingMembers(selection.isSelected(member) ? selection.members : [member])

          const initialPositions = new Map(selection.members.map((member) => [member, member.position]))
          let moved = false
          const prev: Point = {
            x: down.clientX,
            y: down.clientY,
          }

          onMouseMoveUntilUp((move) => {
            if (!moved) {
              moved = isMoveThresholdOvercome(down, move)
            }

            if (moved) {
              dragging || setDragging(true)

              const delta: Point = {
                x: move.clientX - prev.x,
                y: move.clientY - prev.y,
              }

              intersections.onDragMove(delta)

              if (selection.isSelected(member)) {
                selection.members.forEach((member) => {
                  member.setPosition({
                    x: member.position.x + delta.x,
                    y: member.position.y + delta.y,
                  })
                })
              } else {
                member.setPosition({
                  x: member.position.x + delta.x,
                  y: member.position.y + delta.y,
                })
              }

              prev.x = move.clientX
              prev.y = move.clientY
            }
          }).then((up) => {
            setDragging(false)
            intersections.setDraggingMembers(null)

            if (moved) {
              const resultingPositions = new Map(selection.members.map((member) => [member, member.position]))

              selection.members.forEach((member) => member.patchPosition())

              history.add({
                undo() {
                  initialPositions.forEach((position, member) => {
                    member.setPosition(position)
                    member.patchPosition()
                  })
                },
                redo() {
                  resultingPositions.forEach((position, member) => {
                    member.setPosition(position)
                    member.patchPosition()
                  })
                },
              })
            } else {
              up.event.shiftKey ? selection.toggle(member) : selection.setMembers([member])
            }
          })
        }}
        ref={ref}
      >
        {children}

        <CalcMemberCaptionView />
      </div>
    </CalcMemberViewContext.Provider>
  )
})
