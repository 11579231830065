import React, { createContext, FC, PropsWithChildren, useMemo } from 'react'
import { DocsListState } from './DocsListState'
import { ApiListDoc } from '../../api/docs/ApiListDoc'

export const DocsListStateContext = createContext({} as DocsListState)

export const DocsListStateContextProvider: FC<PropsWithChildren<{ docs: ApiListDoc[] }>> = ({ children, docs }) => (
  <DocsListStateContext.Provider value={useMemo(() => new DocsListState(docs), [docs])}>
    {children}
  </DocsListStateContext.Provider>
)
