import React, { FC, MouseEventHandler, PropsWithChildren } from 'react'
import { RoutePath } from '../RoutePath'
import { useTranslation } from 'react-i18next'

export const ContentMenu: FC<{ onClick?: MouseEventHandler }> = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <div className="flex gap-3 sm:gap-6">
      <ContentMenuLink onClick={onClick} to={RoutePath.Feedback}>
        {t('Feedback')}
      </ContentMenuLink>

      <ContentMenuLink onClick={onClick} to={RoutePath.Login}>
        {t('Login')}
      </ContentMenuLink>
    </div>
  )
}

export const ContentMenuLink: FC<PropsWithChildren<{ to: RoutePath; onClick?: MouseEventHandler }>> = ({
  children,
  onClick,
}) => {
  return (
    <button className="hover:underline" onClick={onClick}>
      {children}
    </button>
  )
}
