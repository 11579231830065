import React, { FC, SVGAttributes } from 'react'
import classNames from 'classnames'

export const Logo: FC<SVGAttributes<SVGElement>> = ({ className, children, ...attrs }) => {
  return (
    <svg
      className={classNames('Logo', className)}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attrs}
    >
      <rect x="1" y="1" width="14" height="14" rx="2" fill="#DC6036" />
      <rect x="1" y="17" width="14" height="14" rx="2" fill="#9255F8" />
      <rect x="17" y="1" width="14" height="14" rx="2" fill="#259FF2" />
      <rect x="17" y="17" width="14" height="14" rx="2" fill="#45C171" />
    </svg>
  )
}
