import React, { FC } from 'react'
import { CalcRelationLine } from '../CalcRelationLine'
import { observer } from 'mobx-react-lite'
import { CalcMember } from '../doc/CalcMember'

export const CalcRelationMemberViewMember: FC<{
  relationMember: CalcMember
  member: CalcMember
}> = observer(({ relationMember, member }) => {
  return (
    <CalcRelationLine point1={member.position} point2={relationMember.position} editing={relationMember.valueEditing} />
  )
})
