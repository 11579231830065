import { makeAutoObservable } from 'mobx'
import { CalcDoc } from '../../doc/CalcDoc'
import { ICalcSelection } from './ICalcSelection'
import { CalcMember } from '../../doc/CalcMember'
import { minBy } from 'lodash'
import { distance } from '../../../common/functions/distance'

export class CalcSelectionNavigate {
  constructor(
    private doc: CalcDoc,
    private selection: ICalcSelection,
  ) {
    makeAutoObservable(this)
  }

  up() {
    const source = this.getSourceMember((member1, member2) => member1.position.y > member2.position.y)

    if (source) {
      this.selectNextMember(source, (source, member) => member.position.y < source.position.y)
    }
  }

  down() {
    const source = this.getSourceMember((member1, member2) => member1.position.y < member2.position.y)

    if (source) {
      this.selectNextMember(source, (source, member) => member.position.y > source.position.y)
    }
  }

  left() {
    const source = this.getSourceMember((member1, member2) => member1.position.x < member2.position.x)

    if (source) {
      this.selectNextMember(source, (source, member) => member.position.x < source.position.x)
    }
  }

  right() {
    const source = this.getSourceMember((member1, member2) => member1.position.x > member2.position.x)

    if (source) {
      this.selectNextMember(source, (source, member) => member.position.x > source.position.x)
    }
  }

  private selectNextMember(source: CalcMember, condition: (source: CalcMember, member2: CalcMember) => boolean) {
    const target = minBy(
      this.doc.members.filter((member) => condition(source, member)),
      (member) => distance(source.position, member.position),
    )

    if (target) {
      this.selection.setMembers([target])
    }
  }

  private getSourceMember(condition: (member1: CalcMember, member2: CalcMember) => boolean): CalcMember | null {
    const { members } = this.selection

    if (members.length === 1) {
      return members[0]
    } else if (members.length === 0) {
      if (this.doc.members.length === 0) {
        return null
      } else {
        return this.doc.members.reduce((result, member) => (condition(result, member) ? member : result))
      }
    } else {
      return members.reduce((result, member) => (condition(result, member) ? member : result))
    }
  }
}
