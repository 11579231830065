import React, { Dispatch, DispatchWithoutAction, FC, KeyboardEvent, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { ResizableInput } from '../../common/components/ResizableInput'
import { MAX_EXPRESSION_LENGTH } from '../../constants'
import { CalcMemberExpression } from '../doc/CalcMemberExpression'

export const CalcMemberExpressionInput: FC<{
  onFinish: Dispatch<CalcMemberExpression>
  onCancel: DispatchWithoutAction
  onKeyDown?: Dispatch<KeyboardEvent>
  expression: CalcMemberExpression
}> = observer(({ onFinish, onCancel, expression, onKeyDown }) => {
  const [error, setError] = useState(false)
  const clone = useMemo(() => expression.clone(), [expression])

  return (
    <ResizableInput
      commonClassName="text-2xl h-7"
      className={classNames(error && 'animate-shake')}
      onAnimationEnd={() => {
        setError(false)
      }}
      name="expression"
      autoComplete="off"
      autoCorrect="off"
      maxLength={MAX_EXPRESSION_LENGTH}
      value={clone.value}
      onMouseDown={(event) => {
        // to prevent dnd of element when selecting using cursor
        event.stopPropagation()
      }}
      onChange={(event) => {
        clone.setValue(event.target.value)
      }}
      onKeyDown={(event) => {
        event.stopPropagation()

        if (event.key === 'Escape') {
          onCancel()
        } else if (event.key === '(') {
          const input = event.target as HTMLInputElement
          const start: number | null = input.selectionStart
          const end: number | null = input.selectionEnd

          if (input.value && typeof start === 'number' && typeof end === 'number') {
            event.preventDefault()

            if (end === start) {
              input.value = input.value.substring(0, start) + '()' + input.value.substring(end)

              input.setSelectionRange(start + 1, start + 1)
            } else {
              input.value =
                input.value.substring(0, start) +
                '(' +
                input.value.substring(start, end) +
                ')' +
                input.value.substring(end)

              input.setSelectionRange(start, end + 2)
            }
          }
        } else if (event.key === 'Enter') {
          if (clone.valid) {
            onFinish(clone)
          } else {
            setError(true)
          }
        } else {
          onKeyDown?.(event)
        }
      }}
      onBlur={() => {
        if (clone.valid) {
          onFinish(clone)
        } else {
          onCancel()
        }
      }}
      autoFocus
      autoSelect
    />
  )
})
