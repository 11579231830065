import { makeAutoObservable } from 'mobx'
import { getNextMemberPosition } from '../functions/getNextMemberPosition'
import { CalcDoc } from '../doc/CalcDoc'
import { CalcMember } from '../doc/CalcMember'
import { generateMemberId } from '../functions/generateMemberId'

export class CalcNewMember {
  member: CalcMember | null = null

  constructor(private doc: CalcDoc) {
    makeAutoObservable(this)
  }

  create(position = getNextMemberPosition(this.doc.members)) {
    // TODO: should be virtual member to avoid patch creating
    this.member = new CalcMember(this.doc, generateMemberId(), position)
  }

  cancel() {
    this.member = null
  }
}
