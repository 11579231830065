import { Link } from 'react-router-dom'
import { RoutePath } from '../RoutePath'
import { Logo } from '../components/Logo'
import { APP_TITLE } from '../../constants'
import React from 'react'

export function ContentLogo() {
  return (
    <Link to={RoutePath.Home} className="flex items-center gap-3 text-xl font-medium hover:underline">
      <Logo /> {APP_TITLE}
    </Link>
  )
}
