import React, { FC, MouseEventHandler } from 'react'
import { ContentContainer } from './ContentContainer'
import { ContentNav } from './ContentNav'
import { ContentTryButton } from './ContentTryButton'

export const ContentFooter: FC<{ onClick: MouseEventHandler }> = ({ onClick }) => (
  <ContentContainer>
    <div className="flex items-center justify-between">
      <ContentNav onClick={onClick} />
      <ContentTryButton compact onClick={onClick} />
    </div>
  </ContentContainer>
)
