import React, { FC, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { distance } from '../common/functions/distance'
import { angle } from '../common/functions/angle'

import { Point } from '../common/Point'
import { CalcContext } from './context/CalcContext'
import classNames from 'classnames'

export const CalcRelationLine: FC<{ point1: Point; point2: Point; editing: boolean }> = observer(
  ({ point1, point2, editing }) => {
    const { scale } = useContext(CalcContext)
    const p1 = scale.calcPosition(point1)
    const p2 = scale.calcPosition(point2)

    return (
      <div
        className={classNames(
          'pointer-events-none fixed left-32 top-32 h-px origin-top-left group-hover:bg-neutral-700',
          editing ? 'bg-neutral-700' : 'bg-neutral-200',
        )}
        style={{
          left: p1.x,
          top: p1.y,
          rotate: `${angle(p1, p2)}deg`,
          width: distance(p1, p2),
        }}
      />
    )
  },
)
