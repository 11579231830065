import React, { FC, useContext, useState } from 'react'
import { CalcDocNote } from '../doc/CalcDocNote'
import { Point } from '../../common/Point'
import { onMouseMoveUntilUp } from '../../common/functions/onMouseMoveUntilUp'
import { CalcContext } from '../context/CalcContext'
import { observer } from 'mobx-react-lite'

export const CalcSingleNote: FC<{
  note: CalcDocNote
}> = observer(({ note }) => {
  const { history, scale } = useContext(CalcContext)
  const [, setEditing] = useState(false)
  const position = scale.calcPosition(note.position)

  return (
    <div
      className="fixed -mt-2.5 inline-flex w-[320px] -translate-x-1/2 cursor-default break-words text-xs leading-5"
      style={{
        top: position.y,
        left: position.x,
      }}
      onDoubleClick={(event) => {
        event.preventDefault()

        const textarea = event.target as HTMLTextAreaElement

        setEditing(true)
        textarea.focus()
      }}
      onMouseDown={(down) => {
        down.stopPropagation()
        down.preventDefault()

        const initial: Point = note.position
        const previous: Point = Point.fromEvent(down)

        onMouseMoveUntilUp((move) => {
          const delta: Point = {
            x: move.clientX - previous.x,
            y: move.clientY - previous.y,
          }

          note.setPosition(Point.shift(note.position, delta))

          previous.x = move.clientX
          previous.y = move.clientY
        }).then(() => {
          if (!Point.isEqual(initial, note.position)) {
            const { position } = note

            history.add({
              undo() {
                note.setPosition(initial)
              },
              redo() {
                note.setPosition(position)
              },
            })
          }
        })
      }}
    >
      {note.content}
    </div>
  )
})
