import React, { Dispatch, FC, MouseEvent, ReactNode } from 'react'
import classNames from 'classnames'

export interface CalcHeaderMenuItem {
  title: ReactNode
  onClick?: Dispatch<MouseEvent>
  icon?: ReactNode
  disabled?: boolean
  closeOnClick?: boolean
  hotkey?: ReactNode
  children?: Array<CalcHeaderMenuItem | null>
}

export const CalcHeaderMenu: FC<{
  items: Array<CalcHeaderMenuItem | null>
}> = ({ items }) => {
  return (
    <ul>
      {items.map((item, index) => (
        <li className="relative min-w-[200px]" key={index}>
          {item === null ? (
            <div className="my-1.5 h-px bg-white/10" />
          ) : (
            <button
              className="flex h-7 w-full items-center gap-2.5 whitespace-nowrap px-2.5 text-left hover:bg-neutral-900 active:bg-neutral-950 disabled:opacity-30 disabled:hover:bg-transparent"
              onClick={(event) => {
                item.onClick?.(event)

                if (item?.closeOnClick) {
                  // todo: close
                }
              }}
              disabled={item.disabled}
            >
              <div className="w-3">{item.icon}</div>
              <div className="grow">{item.title}</div>

              {item.hotkey && <div className={classNames('pl-5 tracking-widest')}>{item.hotkey}</div>}
            </button>
          )}
        </li>
      ))}
    </ul>
  )
}
