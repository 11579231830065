import React, { FC } from 'react'
import { ContentContainer } from '../common/content/ContentContainer'
import { ContentNav } from '../common/content/ContentNav'

export const Guides: FC = () => {
  return (
    <ContentContainer>
      <ContentNav />
    </ContentContainer>
  )
}
