import React, { FC, useContext } from 'react'
import { CalcHeaderDropdownMenuButton } from '../common/CalcHeaderDropdownMenuButton'
import { useTranslation } from 'react-i18next'
import { MousePointerSquareDashed } from 'lucide-react'
import { CalcContext } from '../../context/CalcContext'
import { observer } from 'mobx-react-lite'

export const CalcHeaderSelectMenu: FC = observer(() => {
  const { t } = useTranslation()
  const { selection, clipboard } = useContext(CalcContext)

  return (
    <CalcHeaderDropdownMenuButton
      items={[
        {
          title: t('Select all'),
          onClick() {
            selection.selectAllVisible()
          },
          hotkey: '⌘A',
        },
        null,
        {
          title: t('Group selected'),
          onClick() {},
          hotkey: '⌘G',
          disabled: true,
        },
        null,
        {
          title: t('Duplicate'),
          onClick() {
            selection.generation.duplicate()
          },
          hotkey: '⌘D',
          disabled: selection.members.length === 0,
        },
        {
          title: t('Copy'),
          onClick() {
            clipboard.copySelected()
          },
          hotkey: '⌘C',
          disabled: selection.members.length === 0,
        },
        {
          title: t('Paste'),
          onClick() {
            clipboard.pasteCopied()
          },
          hotkey: '⌘V',
          disabled: clipboard.members.length === 0,
        },
        null,
        {
          title: t('Create expression'),
          onClick() {},
          hotkey: '⌘A',
          disabled: true,
        },
      ]}
      dropdownClassName="-translate-x-9"
    >
      <MousePointerSquareDashed className="w-5" strokeWidth={1.5} />
    </CalcHeaderDropdownMenuButton>
  )
})
