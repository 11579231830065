import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { DocsListGroup } from './DocsListGroup'
import { DocsListStateContext } from './DocsListStateContext'

export const DocsList: FC = observer(() => {
  const { t } = useTranslation()
  const { favorites, recent } = useContext(DocsListStateContext)

  return (
    <>
      {favorites.length > 0 && <DocsListGroup title={t('Favorites')} docs={favorites} />}
      {recent.length > 0 && <DocsListGroup title={t('Recent')} docs={recent} />}
    </>
  )
})
