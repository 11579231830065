export const MAX_CAPTION_LENGTH = 64
export const MAX_EXPRESSION_LENGTH = 64
export const MOUSE_MOVE_THRESHOLD = 3
export const HARDCODED_MEMBER_WIDTH = 136
export const HARDCODED_MEMBER_HEIGHT = 52
export const MEMBERS_GAP = 10
export const MAX_MEMBERS_QUANTITY = 128
export const MAX_NOTES_QUANTITY = 32
export const MAX_NOTE_LENGTH = 255
export const DOC_NAME_MAX_LENGTH = 40
export const APP_TITLE = 'calc.ing'
