import React, { FC } from 'react'
import { CalcMemberView } from './CalcMemberView'
import { CalcRelationMembersView } from './CalcRelationMembersView'
import { observer } from 'mobx-react-lite'
import { CalcMember } from '../doc/CalcMember'
import { CalcRelationMemberViewMembers } from './CalcRelationMemberViewMembers'
import { CalcExpressionMembers } from './CalcExpressionMembers'
import { useCalcMembers } from '../useCalcMembers'

interface Props {
  member: CalcMember
}

export const CalcRelationMemberView: FC<Props> = observer(({ member }) => {
  const members = useCalcMembers(member.expression.memberIds)

  return (
    <div className="group">
      <CalcMemberView member={member}>
        <CalcRelationMembersView />
      </CalcMemberView>

      <CalcRelationMemberViewMembers member={member} members={members} />

      {member.valueEditing && member.expression.memberIds.length > 0 && <CalcExpressionMembers member={member} />}
    </div>
  )
})
