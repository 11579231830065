import React, { FC, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { CalcMember } from '../doc/CalcMember'
import { CalcMemberExpression } from '../doc/CalcMemberExpression'
import { CalcContext } from '../context/CalcContext'

export const CalcExpressionMember: FC<{ member: CalcMember; expressionMember: CalcMember; index: number }> = observer(
  ({ expressionMember, index }) => {
    const { scale } = useContext(CalcContext)
    const position = scale.calcPosition(expressionMember.position)

    return (
      <div
        className="fixed z-20 -mt-7 flex -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded bg-black px-1 py-px text-[10px] font-bold text-white"
        style={{
          top: position.y,
          left: position.x,
        }}
      >
        {CalcMemberExpression.getScopeVariableNumber(index)}
      </div>
    )
  },
)
