import React, { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'

export const CalcHeaderDropdown: FC<
  PropsWithChildren & {
    direction?: 'left' | 'right'
    className?: string
  }
> = ({ children, className, direction = 'right' }) => {
  return (
    <div className={classNames('absolute top-full p-1.5', direction === 'left' ? 'right-0' : 'left-0', className)}>
      <div className="rounded bg-neutral-800 py-2 shadow">{children}</div>
    </div>
  )
}
