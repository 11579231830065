import { CalcMember } from '../CalcMember'
import { CalcHistoryAction } from '../../context/CalcActionsHistory'
import { CalcDoc } from '../CalcDoc'
import { CalcMemberElements } from '../../context/CalcMemberElements'
import { CalcMemberDestructCaptionFactory } from './CalcDestructCaptionFactory'
import { Point } from '../../../common/Point'
import { CalcMemberExpression } from '../CalcMemberExpression'
import { CalcOperationType } from '../../operations/CalcOperationType'

export class CalcExpressionMemberDestructurizer {
  constructor(
    private doc: CalcDoc,
    private elements: CalcMemberElements,
  ) {}

  destructExpressionMember(member: CalcMember): CalcHistoryAction {
    const { width, height } = this.elements.getDimensions(member)
    const x = (2 * width) / 3
    const y = (5 * height) / 3
    const caption = CalcMemberDestructCaptionFactory.getCaption(member, 2)
    const initialExpression = member.expression

    const first = this.doc.createMember(
      Point.shift(member.position, {
        x: -x,
        y,
      }),
      new CalcMemberExpression(this.doc, `${member.roundedValue.toString(10)}/2`),
    )
    if (caption) {
      first.setCaption(caption)
    }

    const second = this.doc.createMember(
      Point.shift(member.position, {
        x,
        y,
      }),
      new CalcMemberExpression(this.doc, `${member.roundedValue.toString(10)}/2`),
    )
    if (caption) {
      second.setCaption(caption)
    }

    const nextExpression = CalcMemberExpression.fromOperation(this.doc, [first, second], CalcOperationType.Sum)
    member.setExpression(nextExpression)

    this.doc.addMembers([first, second])

    return {
      undo: () => {
        member.setExpression(initialExpression)
        this.doc.removeMembers([first, second])
      },
      redo: () => {
        member.setExpression(nextExpression)
        this.doc.addMembers([first, second])
      },
    }
  }
}
