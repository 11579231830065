import { makeAutoObservable } from 'mobx'
import { ICalcSelection } from './ICalcSelection'
import { CalcMemberElements } from '../CalcMemberElements'
import { CalcMembersArrangeClusterer } from '../arrange/CalcMembersArrangeClusterer'
import { CalcActionsHistory } from '../CalcActionsHistory'

export class CalcSelectionArrange {
  private clusterer = new CalcMembersArrangeClusterer(this.elements, this.history)

  constructor(
    private selection: ICalcSelection,
    private elements: CalcMemberElements,
    private history: CalcActionsHistory,
  ) {
    makeAutoObservable(this)
  }

  cluster() {
    this.clusterer.clusterMembers(this.selection.members)
  }
}
