import React, { FC } from 'react'
import { StarIcon } from '@heroicons/react/24/outline'
import { StarIcon as SolidStarIcon } from '@heroicons/react/24/solid'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { DocsListDocButton } from './DocsListDocButton'
import { ApiListDoc } from '../../api/docs/ApiListDoc'
import { runInAction } from 'mobx'
import { apiClient } from '../../api/ApiClient'

export const DocsListDocFavorite: FC<{
  doc: ApiListDoc
}> = observer(({ doc }) => {
  const { t } = useTranslation()

  return (
    <DocsListDocButton
      onClick={async () => {
        const originalFavorite = doc.favorite

        runInAction(() => {
          doc.favorite = !originalFavorite
        })

        apiClient.docs
          .patch(doc.id, {
            favorite: doc.favorite,
          })
          .catch(() => {
            runInAction(() => {
              doc.favorite = originalFavorite
            })
          })
      }}
      title={doc.favorite ? t('Remove from favorites') : t('Add to favorites')}
    >
      {doc.favorite ? <SolidStarIcon className="w-5" /> : <StarIcon className="w-5" />}
    </DocsListDocButton>
  )
})
