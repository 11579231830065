import React, { ButtonHTMLAttributes, FC } from 'react'
import classNames from 'classnames'

export const CalcHeaderButton: FC<ButtonHTMLAttributes<HTMLButtonElement> & { active?: boolean }> = ({
  className,
  children,
  active,
  ...attrs
}) => {
  return (
    <button
      className={classNames(
        'flex h-full min-w-[48px] items-center justify-center gap-0.5 px-2',
        className,
        active ? 'bg-black' : 'hover:bg-black/20 active:bg-black/40',
      )}
      type="button"
      {...attrs}
    >
      {children}
    </button>
  )
}
