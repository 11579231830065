import React, { FC, useState } from 'react'
import { RoutePath } from '../../common/RoutePath'
import { Link } from 'react-router-dom'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { formatDistance } from 'date-fns'
import { observer } from 'mobx-react-lite'
import { DocsListDocFavorite } from './DocsListDocFavorite'
import { DocsListDocButton } from './DocsListDocButton'
import { DocsListDocMenu } from './DocsListDocMenu'
import { toggle } from '../../common/functions/toggle'
import { ApiListDoc } from '../../api/docs/ApiListDoc'
import AutosizeInput from 'react-input-autosize'
import { DOC_NAME_MAX_LENGTH } from '../../constants'
import { apiClient } from '../../api/ApiClient'
import { runInAction } from 'mobx'

export const DocsListDocData: FC<{
  doc: ApiListDoc
}> = observer(({ doc }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [renamingState, setRenamingState] = useState<string | null>(null)

  const onRenamingFinish = async (value: string) => {
    const title = value.trim()

    if (title) {
      const originalTitle = doc.title

      runInAction(() => {
        doc.title = title
      })

      apiClient.docs
        .patch(doc.id, {
          title,
        })
        .catch(() => {
          runInAction(() => {
            doc.title = originalTitle
          })
        })
    }

    setRenamingState(null)
  }

  return (
    <>
      {renamingState === null ? (
        <Link
          to={RoutePath.FilesById.replace(':id', doc.id)}
          className="flex w-full flex-col px-1.5 py-2 sm:flex-row sm:items-center sm:gap-2"
        >
          <div className="grow truncate">{doc.title}</div>

          <div className="whitespace-nowrap text-sm text-neutral-400">
            {formatDistance(doc.openedAt, new Date(), {
              addSuffix: true,
            })}
          </div>
        </Link>
      ) : (
        <div className="flex w-full flex-col px-1.5 py-2 sm:flex-row sm:items-center sm:gap-2">
          <div className="grow">
            <AutosizeInput
              value={renamingState}
              autoFocus
              maxLength={DOC_NAME_MAX_LENGTH}
              onChange={(event) => {
                setRenamingState(event.target.value)
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  onRenamingFinish(renamingState)
                } else if (event.key === 'Escape') {
                  setRenamingState(null)
                }
              }}
              onBlur={() => {
                onRenamingFinish(renamingState)
              }}
            />
          </div>

          <div className="whitespace-nowrap text-sm text-neutral-400">
            {formatDistance(doc.openedAt, new Date(), {
              addSuffix: true,
            })}
          </div>
        </div>
      )}

      <DocsListDocFavorite doc={doc} />

      <div className="relative">
        <DocsListDocButton onClick={toggle(setMenuOpen)} active={menuOpen}>
          <EllipsisVerticalIcon className="w-5" />
        </DocsListDocButton>

        {menuOpen && (
          <DocsListDocMenu
            doc={doc}
            onHide={() => {
              setMenuOpen(false)
            }}
            onRename={() => {
              setRenamingState(doc.title)
            }}
          />
        )}
      </div>
    </>
  )
})
