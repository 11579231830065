import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { ContentNav } from '../common/content/ContentNav'
import { ContentContainer } from '../common/content/ContentContainer'

export const Feedback: FC = () => {
  return (
    <>
      <Helmet>
        <title>Feedback</title>
      </Helmet>

      <ContentContainer>
        <ContentNav />
        <div className="Feedback">Feedback</div>
      </ContentContainer>
    </>
  )
}
