import { makeAutoObservable } from 'mobx'
import { ICalcSelection } from './ICalcSelection'

export class CalcSelectionDestruction {
  constructor(private selection: ICalcSelection) {
    makeAutoObservable(this)
  }

  remove() {
    this.selection.members.forEach((member) => member.setDeleted(true))
    this.selection.deselect()
  }
}
