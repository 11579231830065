import React, { FC } from 'react'
import { CalcHeaderDropdownMenuButton } from '../common/CalcHeaderDropdownMenuButton'
import { useTranslation } from 'react-i18next'
import { Logo } from '../../../docs/header/Logo'

export const CalcHeaderFileMenu: FC = () => {
  const { t } = useTranslation()

  return (
    <CalcHeaderDropdownMenuButton
      items={[
        {
          title: t('New'),
          hotkey: '⌘N',
          onClick() {},
        },
        null,
        {
          title: t('Feedback'),
          onClick() {},
        },
        {
          title: t('Exit'),
          onClick() {},
        },
      ]}
    >
      <Logo strokeWidth={1.25} className="w-5" />
    </CalcHeaderDropdownMenuButton>
  )
}
