import { CalcMember } from '../CalcMember'

export class CalcMemberDestructCaptionFactory {
  static getCaption({ caption }: CalcMember, childrenCount: number): string | null {
    if (!caption) {
      return null
    }

    const matches = caption.match(/^(.*) \/ ([\d]+)$/im) // in case when number is going to be divided again

    if (matches) {
      const int = parseInt(matches[2])

      if (isFinite(int)) {
        return matches[1] + ' / ' + int * childrenCount
      }
    }

    return caption + ' / ' + childrenCount
  }
}
