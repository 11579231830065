import { makeAutoObservable } from 'mobx'

export interface CalcHistoryAction {
  undo(): void

  redo(): void
}

export class CalcActionsHistory {
  readonly actions: CalcHistoryAction[] = []
  readonly undoActions: CalcHistoryAction[] = []

  constructor() {
    makeAutoObservable(this)
  }

  get currentAction(): CalcHistoryAction | null {
    return this.actions[this.actions.length - 1] || null
  }

  add(action: CalcHistoryAction) {
    this.undoActions.length = 0
    this.actions.push(action)
  }

  redo() {
    const action = this.undoActions.pop()

    if (action) {
      try {
        action.redo()
        this.actions.push(action)
      } catch (e) {
        console.error(e)
      }
    }
  }

  undo() {
    const action = this.actions.pop()

    if (action) {
      try {
        action.undo()
        this.undoActions.push(action)
      } catch (e) {
        console.error(e)
      }
    }
  }
}
