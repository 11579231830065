import { CalcMember } from '../doc/CalcMember'
import { Link, Node } from 'webcola'
import { findIndex } from 'lodash'

export function getCalcMemberWebColaLinks(members: CalcMember[]): Link<Node | number>[] {
  return members.reduce<Link<Node | number>[]>((links, member, target) => {
    member.expression.memberIds.forEach((expressionMemberId) => {
      if (expressionMemberId) {
        const source = findIndex(members, (member) => member.id === expressionMemberId)

        if (source !== -1) {
          links.push({ source, target })
        }
      }
    })

    return links
  }, [])
}
