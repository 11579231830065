import React, { FC, PropsWithChildren, ReactNode, useRef, useState } from 'react'
import { CalcHeaderChevron } from './CalcHeaderChevron'
import { CalcHeaderButton } from './CalcHeaderButton'
import { CalcHeaderDropdown } from './CalcHeaderDropdown'
import { toggle } from '../../../common/functions/toggle'
import { useClickAway } from 'ahooks'

export const CalcHeaderDropdownButton: FC<
  PropsWithChildren<{
    content: ReactNode
    direction?: 'left' | 'right'
    dropdownClassName?: string
  }>
> = ({ content, children, direction, dropdownClassName }) => {
  const [open, setOpen] = useState(false)
  const elementRef = useRef<HTMLDivElement | null>(null)

  useClickAway(
    () => {
      setOpen(false)
    },
    elementRef,
    ['mousedown', 'click', 'focusin'],
  )

  return (
    <div className="relative h-full" ref={elementRef}>
      <CalcHeaderButton onClick={toggle(setOpen)} active={open}>
        {children}

        <CalcHeaderChevron />
      </CalcHeaderButton>

      {open && (
        <CalcHeaderDropdown direction={direction} className={dropdownClassName}>
          {content}
        </CalcHeaderDropdown>
      )}
    </div>
  )
}
