import { makeAutoObservable } from 'mobx'
import { CalcMember } from './CalcMember'
import { MAX_MEMBERS_QUANTITY, MAX_NOTES_QUANTITY } from '../../constants'
import toast from 'react-hot-toast'
import { t } from '../../i18n'
import { CalcDocNote } from './CalcDocNote'
import { Point } from '../../common/Point'
import { CalcMemberExpression } from './CalcMemberExpression'
import { generateMemberId } from '../functions/generateMemberId'
import { ApiDoc } from '../../api/docs/ApiDoc'
import { calcPatchStorage } from './CalcPatchStorage'
import { MemberValueFactory } from './MemberValueFactory'
import { CalcDocIdProvider } from './CalcDocIdProvider'

export class CalcDoc implements MemberValueFactory, CalcDocIdProvider {
  id: string
  title: string
  members: CalcMember[]
  notes: CalcDocNote[]

  constructor(doc: ApiDoc) {
    this.id = doc.id
    this.title = doc.title
    this.members = Object.entries(doc.members).map(([id, memberSource]) => {
      const member = new CalcMember(
        this,
        id,
        Point.fromTuple(memberSource.position),
        CalcMemberExpression.fromTuple(this, memberSource.expression),
      )

      if (memberSource.caption) {
        member.caption = memberSource.caption
      }

      return member
    })
    this.notes = []

    makeAutoObservable(this)
  }

  get visibleMembers(): CalcMember[] {
    return this.members.filter((member) => !member.hidden)
  }

  getMemberById(id: string): CalcMember | null {
    // todo: optimize search (use map)
    return this.members.find((member) => member.id === id) || null
  }

  getMemberValue(id: string): number {
    const member = this.getMemberById(id)

    return member ? member.value : 0
  }

  createMember(position: Point, expression = new CalcMemberExpression(this)): CalcMember {
    return new CalcMember(this, generateMemberId(), position, expression)
  }

  setTitle(value: string) {
    this.title = value
  }

  // the only method to add members
  addMembers(members: CalcMember[]): boolean {
    if (this.checkMembersCount(members.length)) {
      this.members.push(...members)

      return true
    }

    toast.error(t(`Maximum number of members reached`))

    return false
  }

  addMember(member: CalcMember): boolean {
    const added = this.addMembers([member])

    if (added) {
      calcPatchStorage.patchMember(this.id, member.id, {
        position: [member.position.x, member.position.y],
        expression: [member.expression.value, ...member.expression.memberIds],
        caption: member.caption,
      })
    }

    return added
  }

  addNote(position: Point): boolean {
    if (this.checkNotesCount()) {
      this.notes.push(new CalcDocNote(position, 'Default note content value'))

      return true
    }

    toast.error(t(`Maximum number of notes reached`))

    return false
  }

  removeMembers(members: CalcMember[]) {
    const membersToRemove = new Set(members)

    this.members = this.members.filter((member) => !membersToRemove.has(member))
  }

  private checkMembersCount(additionalCount: number): boolean {
    return MAX_MEMBERS_QUANTITY >= this.members.length + additionalCount
  }

  private checkNotesCount(additionalCount: number = 1): boolean {
    return MAX_NOTES_QUANTITY >= this.notes.length + additionalCount
  }
}
