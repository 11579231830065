import { CalcMember } from '../CalcMember'
import { CalcActionsHistory, CalcHistoryAction } from '../../context/CalcActionsHistory'
import { CalcDoc } from '../CalcDoc'
import { CalcMemberElements } from '../../context/CalcMemberElements'
import { CalcExpressionMemberDestructurizer } from './CalcExpressionMemberDestructurizer'
import { CalcRelationMemberDestructurizer } from './CalcRelationMemberDestructurizer'

export class CalcMemberDestructurizer {
  private expressionDestructurizer = new CalcExpressionMemberDestructurizer(this.doc, this.elements)
  private relationDestructurizer = new CalcRelationMemberDestructurizer(this.doc, this.elements)

  constructor(
    private doc: CalcDoc,
    private elements: CalcMemberElements,
    private history: CalcActionsHistory,
  ) {}

  destructMember(member: CalcMember) {
    const historyAction: CalcHistoryAction = member.relation
      ? this.relationDestructurizer.destructRelationMember(member)
      : this.expressionDestructurizer.destructExpressionMember(member)

    this.history.add(historyAction)
  }
}
