import { LocalStorageKey } from '../common/LocalStorageKey'
import { decodeJwtPayload } from '../auth/decodeJwtPayload'
import { ApiClientAuth } from './auth/ApiClientAuth'

export class ApiTokenFactory {
  constructor(private auth: ApiClientAuth) {}

  async getFreshAccessToken(): Promise<string | null> {
    const accessToken = localStorage.getItem(LocalStorageKey.AccessToken)

    if (accessToken) {
      if (this.isTokenExpiringSoon(accessToken)) {
        return (await this.refreshToken()) || accessToken
      }

      return accessToken
    }

    return null
  }

  private async refreshToken(): Promise<string | null> {
    const refreshToken = localStorage.getItem(LocalStorageKey.RefreshToken)

    if (refreshToken) {
      try {
        const response = await this.auth.refreshToken({ refreshToken })

        localStorage.setItem(LocalStorageKey.AccessToken, response.accessToken)
        localStorage.setItem(LocalStorageKey.RefreshToken, response.refreshToken)

        return response.accessToken
      } catch (error) {
        console.error('Error refreshing access token', error)
      }
    }

    return null
  }

  private isTokenExpiringSoon(token: string, secondsBefore = 60): boolean {
    try {
      const payload = decodeJwtPayload(token)
      const currentSeconds = Math.floor(Date.now() / 1000)
      const expirationSeconds = payload.exp!
      const secondsLeft = expirationSeconds - currentSeconds

      return secondsLeft <= secondsBefore
    } catch (e) {
      return true
    }
  }
}
