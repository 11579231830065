import React, { FC, ReactNode } from 'react'
import clsx from 'clsx'

export const LandingExpressionView: FC<{
  value: ReactNode
  title: ReactNode
  variable?: ReactNode
  className?: string
  variableColorClassName?: string
  bold?: boolean
}> = ({ value, title, variable, variableColorClassName = 'bg-black', bold, className }) => {
  return (
    <div className={clsx('relative flex-col items-center justify-center gap-2 text-center', className)}>
      <div className={clsx('text-2xl tracking-tighter', bold && 'font-bold')}>{value}</div>
      <div className="text-sm text-neutral-500">{title}</div>

      {variable && (
        <div
          className={clsx(
            'absolute bottom-full left-1/2 mb-1 -translate-x-1/2 rounded px-1 py-0.5 text-xs text-white',
            variableColorClassName,
          )}
        >
          {variable}
        </div>
      )}
    </div>
  )
}
