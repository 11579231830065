import { Dispatch, FC, MutableRefObject, useEffect, useRef } from 'react'
import { HotkeysController } from './HotkeysController'

const controller = new HotkeysController()

// todo: add activeElement tracking
// todo: add direct eventTarget event handling (context/particular hotkey)
// todo: if property is not provided, it's ignored during checking
export interface HotkeyConfig {
  fn: Dispatch<KeyboardEvent>
  k?: string
  up?: boolean
  right?: boolean
  down?: boolean
  left?: boolean
  shift?: boolean
  mod?: boolean
  del?: boolean
  esc?: boolean
  digit?: boolean
  target?: EventTarget | MutableRefObject<EventTarget>
  prevent?: boolean
  stop?: boolean
}

export const Hotkey: FC<HotkeyConfig> = (config) => {
  const configRef = useRef(config)
  configRef.current = config

  useEffect(() => {
    controller.add(configRef)

    return () => {
      controller.delete(configRef)
    }
  }, [])

  return null
}
