import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { DocsListTitle } from './DocsListTitle'
import { useCreateDoc } from '../useCreateDoc'

export const DocsListEmpty: FC = () => {
  const { t } = useTranslation()
  const createDoc = useCreateDoc()

  return (
    <div className="DocsEmpty">
      <DocsListTitle>{t('There is no files')}</DocsListTitle>

      <div className="text-sm text-neutral-500">
        {t('You can')}{' '}
        <button className="text-black underline hover:no-underline" onClick={createDoc}>
          {t('create new one')}
        </button>
        .
      </div>
    </div>
  )
}
