import React, { FC, InputHTMLAttributes, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

export const ResizableInput: FC<
  InputHTMLAttributes<HTMLInputElement> & {
    commonClassName?: string
    autoSelect?: boolean
  }
> = ({ className, commonClassName, children, defaultValue, value, onChange, autoSelect, ...attrs }) => {
  const [internalValue, setInternalValue] = useState(value || defaultValue || '')
  const autoSelectRef = useRef(autoSelect)
  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (autoSelectRef.current) {
      inputRef.current?.select()
    }
  }, [])

  return (
    <div className={classNames('relative', commonClassName)}>
      <div className="min-h-[1.5em] whitespace-nowrap pr-1 text-transparent">{internalValue}</div>

      <input
        className={classNames(
          'absolute left-0 top-0 h-full w-full text-center outline-none',
          className,
          commonClassName,
        )}
        type="text"
        defaultValue={defaultValue}
        value={value}
        onChange={(event) => {
          setInternalValue(event.target.value)

          onChange?.(event)
        }}
        {...attrs}
        ref={inputRef}
      />
    </div>
  )
}
