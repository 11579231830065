import { makeAutoObservable } from 'mobx'
import { CalcDoc } from '../../doc/CalcDoc'
import { ICalcSelection } from './ICalcSelection'
import { CalcMemberDestructurizer } from '../../doc/destruct/CalcMemberDestructurizer'
import { CalcMemberElements } from '../CalcMemberElements'
import { CalcActionsHistory } from '../CalcActionsHistory'
import { CalcOperationType } from '../../operations/CalcOperationType'
import { CalcMember } from '../../doc/CalcMember'
import { CalcMemberExpression } from '../../doc/CalcMemberExpression'
import { Point } from '../../../common/Point'
import { meanBy, truncate } from 'lodash'
import { calcOperationSymbols } from '../../operations/calcOperationSymbols'
import { MAX_CAPTION_LENGTH } from '../../../constants'

export class CalcSelectionOperations {
  private destructurizer = new CalcMemberDestructurizer(this.doc, this.elements, this.history)

  constructor(
    private doc: CalcDoc,
    private selection: ICalcSelection,
    private elements: CalcMemberElements,
    private history: CalcActionsHistory,
  ) {
    makeAutoObservable(this)
  }

  createRelation(type: CalcOperationType) {
    if (this.selection.members.length === 1) {
      this.createSingleRelation(this.selection.members[0], type)
      // todo: add relation on single selection (place new one near)
    } else if (this.selection.members.length > 1) {
      const member = this.createRelationMember(this.selection.members, type)

      if (this.doc.addMember(member)) {
        this.selection.setMembers([member])
      }
    }
  }

  destruction() {
    if (this.selection.members.length === 1) {
      this.destructurizer.destructMember(this.selection.members[0])
    }
  }

  private createRelationMember(members: CalcMember[], type: CalcOperationType): CalcMember {
    const member = this.doc.createMember(
      this.getMembersCenter(members),
      CalcMemberExpression.fromOperation(this.doc, members, type),
    )
    const caption = this.createOperationCaption(members, type)
    member.setCaption(caption)
    return member
  }

  private getMembersCenter(members: CalcMember[]): Point {
    return {
      x: meanBy(members, (member) => member.position.x),
      y: meanBy(members, (member) => member.position.y),
    }
  }

  private createOperationCaption(members: CalcMember[], type: CalcOperationType) {
    return truncate(
      members.map((member) => member.caption || member.value.toString(10)).join(` ${calcOperationSymbols[type]} `),
      {
        length: MAX_CAPTION_LENGTH,
      },
    )
  }

  private createSingleRelation(selectedMember: CalcMember, type: CalcOperationType) {
    if (selectedMember.relation) {
      const member = this.doc.createMember(selectedMember.position)
      member.setValueEditing(true)
      member.setCaptionEditing(true)

      if (this.doc.addMember(member)) {
        selectedMember.expression.addMember(member, type)
      }
    } else {
    }
  }
}
