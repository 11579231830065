import { HotkeyConfig } from './Hotkey'
import { hotkeyNumKeys } from './hotkeyNumKeys'

export function isHotkeyPressed(event: KeyboardEvent, config: HotkeyConfig): boolean {
  if (config.k && event.key !== config.k) {
    return false
  }

  if (typeof config.up === 'boolean' && event.key !== 'ArrowUp') {
    return false
  }

  if (typeof config.right === 'boolean' && event.key !== 'ArrowRight') {
    return false
  }

  if (typeof config.down === 'boolean' && event.key !== 'ArrowDown') {
    return false
  }

  if (typeof config.left === 'boolean' && event.key !== 'ArrowLeft') {
    return false
  }

  if (typeof config.shift === 'boolean' && !event.shiftKey) {
    return false
  }

  if (typeof config.mod === 'boolean') {
    if (!event.ctrlKey && !event.metaKey) {
      return false
    }
  }

  if (typeof config.del === 'boolean') {
    if (event.key !== 'Delete' && event.key !== 'Backspace') {
      return false
    }
  }

  if (typeof config.esc === 'boolean' && event.key !== 'Escape') {
    return false
  }

  if (typeof config.digit === 'boolean' && !hotkeyNumKeys.has(event.key)) {
    return false
  }

  return true
}
