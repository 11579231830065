import { makeAutoObservable } from 'mobx'
import { CalcMember } from '../doc/CalcMember'
import { CalcSelection } from './selection/CalcSelection'
import { CalcDoc } from '../doc/CalcDoc'

export class CalcClipboard {
  members: CalcMember[] = []

  constructor(
    private doc: CalcDoc,
    private selection: CalcSelection,
  ) {
    makeAutoObservable(this)
  }

  copy(members: CalcMember[]) {
    this.members = members.map((member) => member.clone())
  }

  clear() {
    this.members = []
  }

  copySelected() {
    if (this.selection.members.length) {
      this.members = this.selection.members.slice()
    }
  }

  pasteCopied() {
    if (this.members.length) {
      this.doc.addMembers(this.members)
    }
  }
}
