import React, { FC, useContext, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { CalcContext } from '../context/CalcContext'
import { useTranslation } from 'react-i18next'
import { RoutePath } from '../../common/RoutePath'
import AutosizeInput from 'react-input-autosize'
import { DOC_NAME_MAX_LENGTH } from '../../constants'
import { Link } from 'react-router-dom'

export const CalcHeaderName: FC = observer(() => {
  const { t } = useTranslation()
  const { doc } = useContext(CalcContext)
  const initialValueRef = useRef(doc.title)

  return (
    <div className="absolute left-1/2 mx-auto flex h-full -translate-x-1/2 items-center whitespace-nowrap px-3 text-sm text-neutral-400">
      <Link className="hover:text-white" to={RoutePath.Files}>
        {t('Files')}
      </Link>

      <div className="mx-2">/</div>

      <AutosizeInput
        maxLength={DOC_NAME_MAX_LENGTH}
        inputClassName="bg-transparent text-white"
        value={doc.title}
        onKeyDown={(event) => {
          event.stopPropagation()

          if (event.key === 'Enter' || event.key === 'Escape') {
            ;(event.target as HTMLInputElement).blur()
          }
        }}
        onChange={(event) => {
          doc.setTitle(event.target.value)
        }}
        onFocus={(event) => {
          event.target.select()

          initialValueRef.current = doc.title
        }}
        onBlur={(event) => {
          if (!event.target.value.trim()) {
            doc.setTitle(initialValueRef.current)
          }
        }}
      />
    </div>
  )
})
