import { AxiosProvider } from '../AxiosProvider'
import { ApiAuthLoginRequest } from './ApiAuthLoginRequest'
import { ApiAuthUpdateTokenRequest } from './ApiAuthUpdateTokenRequest'
import { ApiAuthTokenResponse } from './ApiAuthTokenResponse'

export class ApiClientAuth {
  private readonly path = 'auth'

  constructor(private provider: AxiosProvider) {}

  async login(data: ApiAuthLoginRequest, controller?: AbortController): Promise<ApiAuthTokenResponse> {
    return this.provider.request<ApiAuthTokenResponse>({
      url: this.path + '/login',
      method: 'post',
      data,
      signal: controller?.signal,
    })
  }

  async refreshToken(data: ApiAuthUpdateTokenRequest, controller?: AbortController): Promise<ApiAuthTokenResponse> {
    return this.provider.request<ApiAuthTokenResponse>({
      url: this.path + '/token',
      method: 'post',
      data,
      signal: controller?.signal,
    })
  }
}
