import React, { FC } from 'react'
import { CalcHeaderFileMenu } from './menu/CalcHeaderFileMenu'
import { CalcHeaderName } from './CalcHeaderName'
import { CalcHeaderSelectMenu } from './menu/CalcHeaderSelectMenu'
import { HeaderContainer } from './HeaderContainer'
import { CalcHeaderButton } from './common/CalcHeaderButton'
import { Hand } from 'lucide-react'
import { HeaderUser } from '../../docs/header/HeaderUser'

export const CalcHeader: FC = () => {
  return (
    <HeaderContainer className="fixed left-0 top-0 z-20">
      <CalcHeaderFileMenu />

      <CalcHeaderSelectMenu />

      <CalcHeaderButton>
        <Hand className="w-5" strokeWidth={1.5} />
      </CalcHeaderButton>

      <CalcHeaderName />

      <div className="mx-auto" />

      <HeaderUser />
    </HeaderContainer>
  )
}
