import { Dispatch } from 'react'

export function onMouseMoveUntilUp(move: Dispatch<MouseEvent>): Promise<{ event: MouseEvent; moved: boolean }> {
  return new Promise((resolve) => {
    let moved = false
    const onMove = (event: MouseEvent) => {
      moved = true
      move(event)
    }

    const up = (event: MouseEvent) => {
      window.removeEventListener('mousemove', onMove)
      window.removeEventListener('mouseup', up)

      resolve({ event, moved })
    }

    window.addEventListener('mousemove', onMove)
    window.addEventListener('mouseup', up)
  })
}
