import { computed, makeAutoObservable } from 'mobx'
import { ApiListDoc } from '../../api/docs/ApiListDoc'

export class DocsListState {
  constructor(public docs: ApiListDoc[]) {
    makeAutoObservable(this)
  }

  @computed
  get favorites(): ApiListDoc[] {
    return this.docs.filter((doc) => doc.favorite)
  }

  @computed
  get recent(): ApiListDoc[] {
    return this.docs.filter((doc) => !doc.favorite)
  }
}
