import { CalcMember } from '../doc/CalcMember'
import { makeAutoObservable } from 'mobx'
import { HARDCODED_MEMBER_HEIGHT, HARDCODED_MEMBER_WIDTH } from '../../constants'
import { Dimension } from '../../common/Dimension'

export class CalcMemberElements {
  private map = new WeakMap<CalcMember | HTMLElement>()

  constructor() {
    makeAutoObservable(this)
  }

  set(member: CalcMember, element: HTMLElement) {
    this.map.set(member, element)
  }

  get(member: CalcMember): HTMLElement | undefined {
    return this.map.get(member)
  }

  delete(member: CalcMember) {
    this.map.delete(member)
  }

  getDimensions(member: CalcMember): Dimension {
    const element = this.map.get(member)

    return element
      ? {
          width: element.clientWidth,
          height: element.clientHeight,
        }
      : {
          width: HARDCODED_MEMBER_WIDTH,
          height: HARDCODED_MEMBER_HEIGHT,
        }
  }
}
