import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { ApiListDoc } from '../../api/docs/ApiListDoc'
import { DocsListDocData } from './DocsListDocData'
import { DocsListDocRemoved } from './DocsListDocRemoved'

export const DocsListDoc: FC<{
  doc: ApiListDoc
}> = observer(({ doc }) => {
  return (
    <div className="relative -mx-1.5 flex items-center justify-stretch gap-1.5 rounded pr-1.5 hover:bg-black/5">
      {doc.deleted ? <DocsListDocRemoved doc={doc} /> : <DocsListDocData doc={doc} />}
    </div>
  )
})
