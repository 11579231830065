import React, { Dispatch, DispatchWithoutAction, FC, KeyboardEvent } from 'react'
import { observer } from 'mobx-react-lite'
import { ResizableInput } from '../../common/components/ResizableInput'
import { MAX_CAPTION_LENGTH } from '../../constants'

export const CalcValueCaptionInput: FC<{
  onCancel: DispatchWithoutAction
  onChange: Dispatch<string>
  onTabKeyDown?: Dispatch<KeyboardEvent>
  initialValue?: string
}> = observer(({ onCancel, onChange, onTabKeyDown, initialValue }) => {
  return (
    <ResizableInput
      commonClassName="text-xs h-4"
      className="text-neutral-500"
      defaultValue={initialValue}
      maxLength={MAX_CAPTION_LENGTH}
      onMouseDown={(event) => {
        // to prevent dnd of element when selecting using cursor
        event.stopPropagation()
      }}
      onKeyDown={(event) => {
        event.stopPropagation()

        if (event.key === 'Escape') {
          onCancel()
        } else if (event.key === 'Tab') {
          onTabKeyDown?.(event)
        } else if (event.key === 'Enter') {
          const { value } = event.target as HTMLInputElement

          onChange(value)
        }
      }}
      onBlur={(event) => {
        const { value } = event.target as HTMLInputElement

        onChange(value)
      }}
      autoFocus
      autoSelect
    />
  )
})
