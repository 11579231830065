import React, { FC, useEffect, useState } from 'react'
import { LandingExampleWrapper } from './LandingExampleWrapper'
import { LandingExpressionView } from './LandingExpressionView'
import { LandingExpressionInput } from './LandingExpressionInput'
import clsx from 'clsx'
import { safeEvaluate } from '../common/functions/safeEvaluate'

const initialExpression = '4200 - 10%'

export const LandingExampleSync: FC = () => {
  const [expression, setExpression] = useState(initialExpression)
  const [localAmount, setLocalAmount] = useState(() => safeEvaluate(initialExpression))
  const [remoteAmount, setRemoteAmount] = useState(() => safeEvaluate(initialExpression))
  const [outdated, setOutdated] = useState(false)

  useEffect(() => {
    if (expression) {
      try {
        const value = safeEvaluate(expression)

        if (typeof value === 'number') {
          setLocalAmount(value)
          setOutdated(true)

          const timeoutId = window.setTimeout(() => {
            setRemoteAmount(value)
            setOutdated(false)
          }, 1000)

          return () => {
            window.clearTimeout(timeoutId)
          }
        }
      } catch (e) {}
    }
  }, [expression])

  return (
    <LandingExampleWrapper>
      <div className="flex gap-20">
        <LandingExpressionView
          title="Amount"
          value={localAmount}
          variable="Local"
          variableColorClassName="bg-green-600"
        />

        <LandingExpressionView
          title="Percent"
          value={remoteAmount}
          variable="Remote"
          variableColorClassName="bg-blue-600"
          className={clsx(outdated && 'opacity-50')}
        />
      </div>

      <LandingExpressionView
        title="Expression"
        value={
          <LandingExpressionInput
            expression={expression}
            setExpression={setExpression}
            onBlur={() => {
              if (!expression) {
                setExpression(initialExpression)
              }
            }}
          />
        }
      />
    </LandingExampleWrapper>
  )
}
