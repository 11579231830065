import { CalcOperationType } from './CalcOperationType'

export const calcOperationSymbols: { [key in CalcOperationType]: string } = {
  [CalcOperationType.Sum]: '+',
  [CalcOperationType.Subtraction]: '-',
  [CalcOperationType.Multiplication]: '*',
  [CalcOperationType.Division]: '/',
  [CalcOperationType.Exponentiation]: '^',
  [CalcOperationType.DivisionRemainder]: '%',
}
