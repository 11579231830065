export enum RoutePath {
  Home = '/',
  Files = '/files/',
  NewFile = '/files/new',
  FilesById = '/files/:id',
  Feedback = '/feedback/',
  Guides = '/guides/',
  Login = '/login/',
  Logout = '/logout/',
}
