export function mixinDeep(target: object, patch: object): void {
  for (let key in patch) {
    // @ts-ignore
    mixin(target, key, patch[key])
  }
}

function mixin(target: object, key: string, value: any): void {
  // @ts-ignore
  const obj = target[key]

  if (isObject(value) && isObject(obj)) {
    mixinDeep(obj, value)
  } else {
    // @ts-ignore
    target[key] = value
  }
}

function isObject(value: any): boolean {
  return typeof value === 'object' && value !== null && !Array.isArray(value)
}
