import { Point } from '../Point'

export function angle(point: Point, point2: Point): number {
  const dy = point2.y - point.y
  const dx = point2.x - point.x
  let theta = Math.atan2(dy, dx) // range (-PI, PI]
  theta *= 180 / Math.PI // rads to degs, range (-180, 180]
  //if (theta < 0) theta = 360 + theta; // range [0, 360)
  return theta
}
