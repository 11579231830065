import React, { FC, HTMLAttributes } from 'react'
import classNames from 'classnames'

export const ContentContainer: FC<HTMLAttributes<HTMLDivElement>> = ({ className, children, ...attrs }) => {
  return (
    <div className={classNames('mx-auto max-w-7xl px-4', className)} {...attrs}>
      {children}
    </div>
  )
}
