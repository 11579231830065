import { useMemo } from 'react'
import { CalcDoc } from '../doc/CalcDoc'
import { useLoaderData } from 'react-router-dom'
import { ApiDocGetResponse } from '../../api/docs/ApiDocGetResponse'

export function useCalcDoc(): CalcDoc {
  const data = useLoaderData() as ApiDocGetResponse

  return useMemo(() => new CalcDoc(data.doc), [data])
}
