export class Point {
  x: number
  y: number

  // to clone
  constructor(point: Point) {
    this.x = point.x
    this.y = point.y
  }

  static shift(point: Point, shift: Point): Point {
    return {
      x: point.x + shift.x,
      y: point.y + shift.y,
    }
  }

  static fromTuple(tuple?: [number, number]): Point {
    if (tuple) {
      return {
        x: tuple[0],
        y: tuple[1],
      }
    }

    return this.zero()
  }

  static between(point1: Point, point2: Point): Point {
    return {
      x: (point1.x + point2.x) / 2,
      y: (point1.y + point2.y) / 2,
    }
  }

  static fromEvent(event: { clientX: number; clientY: number }): Point {
    return {
      x: event.clientX,
      y: event.clientY,
    }
  }

  static isEqual(point: Point, another: Point): boolean {
    return point.x === another.x && point.y === another.y
  }

  static fromValue(value: number): Point {
    return {
      x: value,
      y: value,
    }
  }

  static fromValues(x: number, y: number): Point {
    return {
      x,
      y,
    }
  }

  static zero(): Point {
    return {
      x: 0,
      y: 0,
    }
  }
}
