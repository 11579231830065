import React, { FC, MouseEventHandler } from 'react'
import { Triangle } from 'lucide-react'

export const LandingVideo: FC<{ onClick?: MouseEventHandler }> = ({ onClick }) => {
  return (
    <div
      className="relative mt-10 aspect-video cursor-pointer rounded-3xl bg-neutral-100 active:bg-neutral-200"
      onClick={onClick}
    >
      <Triangle
        className="absolute left-1/2 top-1/2 h-1/6 w-1/6 -translate-x-1/2 -translate-y-1/2 rotate-90 opacity-20"
        fill="black"
      />
    </div>
  )
}
