import { evaluate } from 'mathjs'

export function safeEvaluate(expression: string, scope: object = {}): number | undefined {
  try {
    if (expression) {
      const value = evaluate(expression, scope)

      if (typeof value === 'number') {
        return value
      }
    }
  } catch (e) {}
}
