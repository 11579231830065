import { Layout } from 'webcola'
import { CalcMember } from '../doc/CalcMember'
import { getCalcMemberWebColaLinks } from './getCalcMemberWebColaLinks'
import { Point } from '../../common/Point'

export function getNextMemberPosition(members: CalcMember[]): Point {
  const newNode = {
    x: 0,
    y: 0,
    width: 92,
    height: 52,
    fixed: 0,
    index: members.length,
  }

  const layout = new Layout()
    .size([document.documentElement.clientWidth, document.documentElement.clientHeight])
    .handleDisconnected(true)
    .avoidOverlaps(true)
    .links(getCalcMemberWebColaLinks(members))
    .nodes(
      members
        .map((value) => ({
          ...value.position,
          width: 92,
          height: 52,
          fixed: 1,
        }))
        .concat(newNode),
    )
    .start()

  const nodes = layout.nodes()

  return new Point(nodes.pop()!)
}
