import { makeAutoObservable, runInAction } from 'mobx'
import { BBox } from '../../common/BBox'
import { Point } from '../../common/Point'
import { ICalcSelection } from './selection/ICalcSelection'
import { CalcDoc } from '../doc/CalcDoc'

export class CalcSelectionCursor {
  bbox: BBox | null = null

  constructor(
    private doc: CalcDoc,
    private selection: ICalcSelection,
  ) {
    makeAutoObservable(this)
  }

  begin([initialStart, initialEnd]: [Point, Point]): (end: Point) => void {
    this.selection.deselect()

    this.bbox = BBox.fromPoints([initialStart, initialEnd])

    return (end: Point) => {
      runInAction(() => {
        // because cursor can be moved to different directions
        this.bbox = BBox.fromPoints([initialStart, end])

        this.selection.setMembers(this.getMembersInsideBBox(this.bbox))
      })
    }
  }

  finish() {
    this.bbox = null
  }

  private getMembersInsideBBox(bbox: BBox) {
    return this.doc.members.filter((member) => BBox.isInside(bbox, member.position))
  }
}
