import React, { FC, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { CalcMember } from '../doc/CalcMember'
import { CalcRelationMemberViewMember } from './CalcRelationMemberViewMember'
import { CalcContext } from '../context/CalcContext'

export const CalcRelationMemberViewMembers: FC<{
  member: CalcMember
  members: CalcMember[]
}> = observer(({ member, members }) => {
  const { hiddenShown } = useContext(CalcContext)

  return (
    <>
      {members.map((m) =>
        member.hidden && !hiddenShown ? null : m ? (
          <CalcRelationMemberViewMember relationMember={member} member={m} key={m.id} />
        ) : null,
      )}
    </>
  )
})
