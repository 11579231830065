import { makeAutoObservable } from 'mobx'
import { Point } from '../../common/Point'

export class CalcScale {
  zero: Point = Point.zero()

  constructor() {
    makeAutoObservable(this)
  }

  setZero(value: Point): void {
    this.zero = value
  }

  calcPosition(position: Point): Point {
    return {
      x: position.x - this.zero.x,
      y: position.y - this.zero.y,
    }
  }
}
