import { MOUSE_MOVE_THRESHOLD } from '../../constants'

export function isMoveThresholdOvercome(
  downEvent: {
    clientX: number
    clientY: number
  },
  moveEvent: {
    clientX: number
    clientY: number
  },
): boolean {
  return (
    Math.abs(downEvent.clientX - moveEvent.clientX) > MOUSE_MOVE_THRESHOLD ||
    Math.abs(downEvent.clientY - moveEvent.clientY) > MOUSE_MOVE_THRESHOLD
  )
}
