import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { DocsHeader } from './header/DocsHeader'
import { ContentContainer } from './ContentContainer'
import { DocsListController } from './list/DocsListController'

export const Docs: FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>{t('Documents')}</title>
      </Helmet>

      <DocsHeader />

      <ContentContainer>
        <DocsListController />
      </ContentContainer>
    </>
  )
}
