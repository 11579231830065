import React, { FC, ReactNode } from 'react'
import { ContentContainer } from '../common/content/ContentContainer'
import clsx from 'clsx'

export const LandingFeature: FC<{
  title: ReactNode
  description: ReactNode
  example?: ReactNode
  shade?: boolean
}> = ({ title, description, example, shade }) => {
  return (
    <div className={clsx('py-14', shade && 'bg-neutral-100')}>
      <ContentContainer>
        <div className="flex flex-col gap-10 md:flex-row md:items-start">
          <div className="md:basis-1/2">
            <h2 className="mb-10 text-4xl font-black tracking-tighter sm:text-5xl">{title}</h2>
            <p className="text-lg text-neutral-700">{description}</p>
          </div>

          {example && <div className="md:basis-1/2">{example}</div>}
        </div>
      </ContentContainer>
    </div>
  )
}
