import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Plus } from 'lucide-react'
import { useCreateDoc } from '../useCreateDoc'

export const DocsHeaderAddButton: FC = () => {
  const { t } = useTranslation()
  const createDoc = useCreateDoc()

  return (
    <button
      className="active:bg-white/15 flex items-center gap-1 rounded bg-white/5 px-3 py-2 hover:bg-white/10"
      onClick={createDoc}
    >
      <Plus className="h-3 w-3" />

      {t('Create file')}
    </button>
  )
}
