import React, { FC, ReactNode } from 'react'
import { DocsListDoc } from './DocsListDoc'
import { DocsListTitle } from './DocsListTitle'
import { observer } from 'mobx-react-lite'
import { ApiListDoc } from '../../api/docs/ApiListDoc'

export const DocsListGroup: FC<{ title: ReactNode; docs: ApiListDoc[] }> = observer(({ title, docs }) => {
  return (
    <>
      {docs.length > 0 && (
        <>
          <DocsListTitle>
            {title} ({docs.length})
          </DocsListTitle>

          <ul className="mb-6">
            {docs.map((doc) => (
              <li key={doc.id}>
                <DocsListDoc doc={doc} />
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  )
})
