import { makeAutoObservable } from 'mobx'
import { CalcMember } from '../../doc/CalcMember'
import { CalcDoc } from '../../doc/CalcDoc'
import { CalcMemberElements } from '../CalcMemberElements'
import { CalcActionsHistory } from '../CalcActionsHistory'
import { CalcSelectionMove } from './CalcSelectionMove'
import { CalcSelectionOperations } from './CalcSelectionOperations'
import { ICalcSelection } from './ICalcSelection'
import { CalcSelectionNavigate } from './CalcSelectionNavigate'
import { CalcSelectionArrange } from './CalcSelectionArrange'
import { CalcSelectionGeneration } from './CalcSelectionGeneration'
import { CalcSelectionVisibility } from './CalcSelectionVisibility'
import { CalcSelectionDestruction } from './CalcSelectionDestruction'
import { CalcSelectionCursor } from '../CalcSelectionCursor'

export class CalcSelection implements ICalcSelection {
  operations = new CalcSelectionOperations(this.doc, this, this.elements, this.history)
  generation = new CalcSelectionGeneration(this.doc, this, this.elements)
  destruction = new CalcSelectionDestruction(this)
  arrange = new CalcSelectionArrange(this, this.elements, this.history)
  move = new CalcSelectionMove(this)
  navigate = new CalcSelectionNavigate(this.doc, this)
  visibility = new CalcSelectionVisibility(this)
  cursor = new CalcSelectionCursor(this.doc, this)

  private membersSet = new Set<CalcMember>()

  constructor(
    private doc: CalcDoc,
    private elements: CalcMemberElements,
    private history: CalcActionsHistory,
  ) {
    makeAutoObservable(this)
  }

  get members(): CalcMember[] {
    return Array.from(this.membersSet)
  }

  toggle(value: CalcMember) {
    this.isSelected(value) ? this.membersSet.delete(value) : this.membersSet.add(value)
  }

  select(member: CalcMember) {
    this.membersSet.add(member)
  }

  deselect() {
    if (this.membersSet.size) {
      this.membersSet.clear()
    }
  }

  isSelected(member: CalcMember): boolean {
    return this.membersSet.has(member)
  }

  setMembers(members: CalcMember[]) {
    this.membersSet.clear()

    members.forEach((member) => {
      this.membersSet.add(member)
    })
  }

  selectAllVisible() {
    this.membersSet = new Set(this.doc.visibleMembers)
  }
}
