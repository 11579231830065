import React, { FC } from 'react'
import { CalcMember } from '../doc/CalcMember'
import { CalcMemberView } from './CalcMemberView'
import { CalcMemberExpression } from './CalcMemberExpression'
import { observer } from 'mobx-react-lite'

interface Props {
  member: CalcMember
}

export const CalcExpressionMemberView: FC<Props> = observer(({ member }) => {
  return (
    <CalcMemberView member={member}>
      <CalcMemberExpression member={member} />
    </CalcMemberView>
  )
})
