import { CalcMember } from '../CalcMember'
import { CalcHistoryAction } from '../../context/CalcActionsHistory'
import { CalcDoc } from '../CalcDoc'
import { CalcMemberElements } from '../../context/CalcMemberElements'

import { Point } from '../../../common/Point'
import { CalcMemberDestructCaptionFactory } from './CalcDestructCaptionFactory'
import { CalcMemberExpression } from '../CalcMemberExpression'
import { CalcOperationType } from '../../operations/CalcOperationType'

export class CalcRelationMemberDestructurizer {
  constructor(
    private doc: CalcDoc,
    private elements: CalcMemberElements,
  ) {}

  destructRelationMember(member: CalcMember): CalcHistoryAction {
    const { height } = this.elements.getDimensions(member)

    if (this.isFurtherDestruct(member)) {
      const caption = CalcMemberDestructCaptionFactory.getCaption(member, member.expression.memberIds.length + 1)
      const another = this.doc.createMember(
        Point.shift(member.position, Point.zero()),
        new CalcMemberExpression(this.doc, `${member.value}/3`),
      )

      if (caption) {
        another.setCaption(caption)
      }

      this.doc.addMember(another)
      member.expression.addMember(another, CalcOperationType.Sum)
    } else {
      const child = this.doc.createMember(
        Point.shift(member.position, {
          x: 0,
          y: height,
        }),
        new CalcMemberExpression(this.doc, `${member.value}/3`),
      )

      if (member.caption) {
        child.setCaption(member.caption)
      }

      child.setValueEditing(true)
      child.setCaptionEditing(true)

      this.doc.addMember(child)
      member.expression.addMember(child, CalcOperationType.Sum)
    }

    return {
      undo() {},
      redo() {},
    }
  }

  private isFurtherDestruct(member: CalcMember): boolean {
    return false
  }
}
