import React, { FC, useContext } from 'react'
import { CalcNewExpressionMember } from './CalcNewExpressionMember'
import { observer } from 'mobx-react-lite'
import { CalcCanvas } from './CalcCanvas'
import { CalcCursorSelectionView } from './CalcCursorSelectionView'
import { CalcMembers } from './value/CalcMembers'
import { CalcHotkeys } from './CalcHotkeys'
import { CalcHeader } from './header/CalcHeader'
import { useEventListener } from 'ahooks'
import { CalcContext } from './context/CalcContext'
import { CalcNotes } from './notes/CalcNotes'
import { Helmet } from 'react-helmet'
import { useLoaderData } from 'react-router-dom'
import { ApiDocGetResponse } from '../api/docs/ApiDocGetResponse'
import { APP_TITLE } from '../constants'

export const Calc: FC = observer(() => {
  const { doc } = useLoaderData() as ApiDocGetResponse
  const { persistence, scale } = useContext(CalcContext)

  useEventListener('beforeunload', (event) => {
    if (persistence.areUnsavedChanges()) {
      event.preventDefault()
      // Included for legacy support, e.g. Chrome/Edge < 119
      event.returnValue = true
    }
  })

  useEventListener(
    'wheel',
    (event) => {
      event.preventDefault()

      scale.setZero({
        x: scale.zero.x + event.deltaX,
        y: scale.zero.y + event.deltaY,
      })
    },
    {
      capture: true,
      passive: false,
    },
  )

  return (
    <>
      <Helmet>
        <body className="relative select-none overflow-hidden" />

        <title>
          {doc.title} - {APP_TITLE}
        </title>
      </Helmet>

      <CalcCanvas />
      <CalcNotes />
      <CalcMembers />
      <CalcCursorSelectionView />
      <CalcNewExpressionMember />
      <CalcHeader />
      <CalcHotkeys />
    </>
  )
})
