import { mixinDeep } from '../../common/mixinDeep'
import { apiClient } from '../../api/ApiClient'
import { CalcMemberPatch } from './CalcMemberPatch'
import { CalcDocPatch } from './CalcDocPatch'

// notes and other data can also store patches here
export class CalcPatchStorage {
  private patch: CalcDocPatch = {}

  async patchMember(docId: string, id: string, patch: CalcMemberPatch): Promise<void> {
    await apiClient.docs.patch(docId, {
      members: {
        [id]: patch,
      },
    })

    mixinDeep(this.patch, {
      members: {
        [id]: patch,
      },
    })
  }
}

export const calcPatchStorage = new CalcPatchStorage()
