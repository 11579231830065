import { CalcMemberExpression } from './CalcMemberExpression'

export class CalcMemberExpressionNormalizer {
  private memberIndexes = new Map<number, number>()
  private members: Array<string> = []
  private value = ''

  constructor(private expression: CalcMemberExpression) {}

  normalize(): void {
    const variableNumbers = this.getVariableNumbers()
    const surroundings = this.getVariableSurroundings()

    if (variableNumbers.length > 0 && surroundings.length > 1) {
      this.value = surroundings.shift()!

      surroundings.forEach((surrounding, index) => {
        const variableNumber = variableNumbers[index]
        const nextMemberIndex = this.getNextMemberIndex(variableNumber)

        this.value += `$${nextMemberIndex + 1}${surrounding}`
      })

      this.expression.value = this.value
      this.expression.memberIds = this.members.map((member) => member)
    } else {
      this.expression.memberIds = []
    }
  }

  private getNextMemberIndex(variableNumber: number): number {
    const index = this.memberIndexes.get(variableNumber)

    if (typeof index === 'number') {
      return index
    }

    const nextIndex = this.memberIndexes.size

    this.memberIndexes.set(variableNumber, nextIndex)
    this.members[nextIndex] = this.getMemberByVariableNumber(variableNumber)

    return nextIndex
  }

  private getMemberByVariableNumber(variableNumber: number): string {
    return this.expression.memberIds[variableNumber - 1]
  }

  private getVariableNumbers(): number[] {
    return Array.from(this.expression.value.matchAll(/\$(\d+)/g)).map((match) => +match[1])
  }

  private getVariableSurroundings(): string[] {
    return this.expression.value.split(/\$\d+/g)
  }
}
