import { Point } from './Point'

/**
 * 0 - - - -
 * - * - - -
 * - - * - -
 * - - - * -
 * - - - - 1
 */
export class BBox {
  0: Point
  1: Point

  static getCenter(bbox: BBox): Point {
    return {
      x: (bbox[0].x + bbox[1].x) / 2,
      y: (bbox[0].y + bbox[1].y) / 2,
    }
  }

  static isInside(bbox: BBox, { x, y }: Point): boolean {
    return x > bbox[0].x && x < bbox[1].x && y > bbox[0].y && y < bbox[1].y
  }

  static fromInfinity(): BBox {
    return [Point.fromValues(Infinity, Infinity), Point.fromValues(-Infinity, -Infinity)]
  }

  static fromPoints(points: Point[]): BBox {
    return points.reduce((bbox, { x, y }) => {
      if (x < bbox[0].x) {
        bbox[0].x = x
      }

      if (x > bbox[1].x) {
        bbox[1].x = x
      }

      if (y < bbox[0].y) {
        bbox[0].y = y
      }

      if (y > bbox[1].y) {
        bbox[1].y = y
      }

      return bbox
    }, BBox.fromInfinity())
  }
}
