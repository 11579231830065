import React, { FC, KeyboardEvent, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { CalcMemberExpressionValue } from './CalcMemberExpressionValue'
import { CalcMemberViewContext } from './CalcMemberViewContext'
import { CalcContext } from '../context/CalcContext'
import { CalcMemberExpressionInput } from './CalcMemberExpressionInput'

export const CalcRelationMembersView: FC = observer(() => {
  const { member } = useContext(CalcMemberViewContext)
  const { selection } = useContext(CalcContext)

  return (
    <>
      {member.valueEditing ? (
        <CalcMemberExpressionInput
          expression={member.expression}
          onKeyDown={(event: KeyboardEvent) => {
            if (event.key === 'Tab') {
              event.preventDefault()

              member.setValueEditing(false)
              member.setCaptionEditing(true)
            }
          }}
          onCancel={() => {
            member.setValueEditing(false)
          }}
          onFinish={(expression) => {
            expression.normalize()

            member.setExpression(expression)
            member.setValueEditing(false)

            if (!member.caption) {
              member.setCaptionEditing(true)
            }
          }}
        />
      ) : (
        <CalcMemberExpressionValue
          className="font-black"
          onEdit={() => {
            selection.deselect()
            member.setValueEditing(true)
          }}
        />
      )}
    </>
  )
})
