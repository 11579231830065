import { ApiDocsCreateRequest } from './ApiDocsCreateRequest'
import { ApiDocsCreateResponse } from './ApiDocsCreateResponse'
import { ApiDocsListResponse } from './ApiDocsListResponse'
import { AxiosProvider } from '../AxiosProvider'
import { ApiDocGetResponse } from './ApiDocGetResponse'
import { ApiDocsUpdateResponse } from './ApiDocsUpdateResponse'
import { ApiDocsUpdateRequest } from './ApiDocsUpdateRequest'
import { ApiDocsDuplicateRequest } from './ApiDocsDuplicateRequest'
import { CalcDocPatch } from '../../calc/doc/CalcDocPatch'

export class ApiClientDocs {
  private readonly path = 'docs'

  constructor(private provider: AxiosProvider) {}

  async patch(docId: string, patch: CalcDocPatch): Promise<void> {
    return this.provider.request<void>({
      url: [this.path, docId].join('/'),
      method: 'patch',
      data: patch,
    })
  }

  async list(signal?: AbortSignal): Promise<ApiDocsListResponse> {
    return this.provider.request<ApiDocsListResponse>({
      url: this.path,
      method: 'get',
      signal,
    })
  }

  async getById(id: string, signal?: AbortSignal): Promise<ApiDocGetResponse> {
    return this.provider.request<ApiDocGetResponse>({
      url: this.path + '/' + id,
      method: 'get',
      signal,
    })
  }

  async update(data: ApiDocsUpdateRequest): Promise<ApiDocsUpdateResponse> {
    return this.provider.request<ApiDocsUpdateResponse>({
      url: this.path + '/' + data.doc.title, // todo: must be ID
      method: 'put',
      data,
    })
  }

  async create(data: ApiDocsCreateRequest): Promise<ApiDocsCreateResponse> {
    return this.provider.request<ApiDocsCreateResponse>({
      url: this.path,
      method: 'post',
      data,
    })
  }

  async duplicate(data: ApiDocsDuplicateRequest, signal?: AbortSignal): Promise<ApiDocsCreateResponse> {
    return this.provider.request<ApiDocsCreateResponse>({
      url: this.path + `/${data.id}/duplicate`,
      method: 'post',
      data: {
        title: data.title,
      },
      signal,
    })
  }
}
