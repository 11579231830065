import React, { DispatchWithoutAction, FC, PropsWithChildren, ReactNode, useContext, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useClickAway } from 'ahooks'
import { ApiListDoc } from '../../api/docs/ApiListDoc'
import { runInAction } from 'mobx'
import { useTranslation } from 'react-i18next'
import { dupliname } from 'dupliname'
import { DocsListStateContext } from './DocsListStateContext'
import { apiClient } from '../../api/ApiClient'
import { Copy, FilePenLine, Trash } from 'lucide-react'

export const DocsListDocMenu: FC<{
  doc: ApiListDoc
  onHide: DispatchWithoutAction
  onRename: DispatchWithoutAction
}> = observer(({ doc, onHide, onRename }) => {
  const { t } = useTranslation()
  const { docs } = useContext(DocsListStateContext)
  const elementRef = useRef<HTMLDivElement | null>(null)

  useClickAway(onHide, elementRef, 'mousedown')

  return (
    <div className="absolute right-0 top-full z-10 -mr-1 -mt-1 rounded bg-white py-1 text-sm shadow" ref={elementRef}>
      <DocsListDocMenuButton
        icon={<FilePenLine className="w-3.5" />}
        onClick={() => {
          onHide()
          onRename()
        }}
      >
        {t('Rename')}
      </DocsListDocMenuButton>

      <DocsListDocMenuButton
        icon={<Copy className="w-3.5" />}
        onClick={() => {
          onHide()

          const title = dupliname(
            doc.title,
            docs.map((doc) => doc.title),
          )

          apiClient.docs
            .duplicate({
              id: doc.id,
              title,
            })
            .then(({ id }) => {
              runInAction(() => {
                docs.unshift({
                  ...doc,
                  title,
                  id,
                  openedAt: new Date().toISOString(),
                })
              })
            })
        }}
      >
        {t('Duplicate')}
      </DocsListDocMenuButton>

      <div className="my-1 h-px bg-neutral-100" />

      <DocsListDocMenuButton
        icon={<Trash className="w-3.5" />}
        onClick={() => {
          onHide()

          runInAction(() => {
            doc.deleted = true
          })

          apiClient.docs
            .patch(doc.id, {
              deleted: true,
            })
            .catch(() => {
              runInAction(() => {
                doc.deleted = false
              })
            })
        }}
      >
        {t('Remove')}
      </DocsListDocMenuButton>
    </div>
  )
})

export const DocsListDocMenuButton: FC<
  PropsWithChildren<{
    onClick?: DispatchWithoutAction
    icon?: ReactNode
  }>
> = observer(({ children, onClick, icon }) => {
  return (
    <button
      className="flex w-full items-center gap-2.5 py-1.5 pl-3 pr-6 text-left hover:bg-black/5 active:bg-black/10"
      onClick={onClick}
    >
      {icon} {children}
    </button>
  )
})
