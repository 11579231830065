import React, { FC, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { CalcMemberViewContext } from './CalcMemberViewContext'
import { CalcValueCaptionInput } from './CalcValueCaptionInput'
import { CalcMemberCaptionValue } from './CalcMemberCaptionValue'
import { CalcContext } from '../context/CalcContext'

export const CalcMemberCaptionView: FC = observer(() => {
  const { member } = useContext(CalcMemberViewContext)
  const { selection } = useContext(CalcContext)

  return !member.valueEditing && member.captionEditing ? (
    <CalcValueCaptionInput
      initialValue={member.caption}
      onCancel={() => {
        member.setCaptionEditing(false)
      }}
      onChange={(value) => {
        member.setCaptionEditing(false)
        member.setCaption(value.trim())
      }}
      onTabKeyDown={(event) => {
        event.preventDefault()

        member.setCaptionEditing(false)
        member.setValueEditing(true)
      }}
    />
  ) : member.caption ? (
    <CalcMemberCaptionValue
      odEdit={() => {
        selection.deselect()
        member.setCaptionEditing(true)
      }}
    />
  ) : null
})
