import React, { FC, useEffect, useState } from 'react'
import { LandingExampleWrapper } from './LandingExampleWrapper'
import { LandingExpressionView } from './LandingExpressionView'
import { LandingExpressionInput } from './LandingExpressionInput'
import { safeEvaluate } from '../common/functions/safeEvaluate'

const var1 = 4200
const var2 = 10
const scope = {
  $1: var1,
  $2: var2,
}

const initialExpression = '$1 - $2%'

export const LandingExampleMembers: FC = () => {
  const [expression, setExpression] = useState(initialExpression)
  const [value, setValue] = useState(() => safeEvaluate(initialExpression))

  useEffect(() => {
    const value = safeEvaluate(expression, scope)
    typeof value === 'number' && setValue(value)
  }, [expression])

  return (
    <LandingExampleWrapper>
      <div className="flex gap-20">
        <LandingExpressionView title="Amount" value={var1} variable="$1" />
        <LandingExpressionView title="Percent" value={var2} variable="$2" />
      </div>

      <LandingExpressionView
        title="Expression"
        value={
          <LandingExpressionInput
            expression={expression}
            setExpression={setExpression}
            onBlur={() => {
              if (!expression) {
                setExpression(initialExpression)
              }
            }}
          />
        }
      />

      <LandingExpressionView title="Result" value={value} bold />
    </LandingExampleWrapper>
  )
}
