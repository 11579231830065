import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom'
import { Root } from './Root'
import { Landing } from './landing/Landing'
import { Feedback } from './feedback/Feedback'
import { Docs } from './docs/Docs'
import { Calc } from './calc/Calc'
import { AuthLayout } from './auth/AuthLayout'
import { Login } from './auth/Login'
import { logoutUser } from './auth/logoutUser'
import React from 'react'
import { CalcContextProvider } from './calc/context/CalcContext'
import { RoutePath } from './common/RoutePath'
import { Guides } from './guides/Guides'

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />}>
      <Route path={RoutePath.Home} element={<Landing />} />
      <Route path={RoutePath.Feedback} element={<Feedback />} />
      <Route path={RoutePath.Guides} element={<Guides />} />
      <Route path={RoutePath.Files} element={<Docs />} />

      <Route
        path={RoutePath.NewFile}
        element={
          <CalcContextProvider>
            <Calc />
          </CalcContextProvider>
        }
      />

      <Route element={<AuthLayout />}>
        <Route path={RoutePath.Login} element={<Login />} />
        <Route path={RoutePath.Logout} action={logoutUser} />
      </Route>
    </Route>,
  ),
)
