import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import toast from 'react-hot-toast'
import { apiClient } from '../api/ApiClient'
import { RoutePath } from '../common/RoutePath'

export function useCreateDoc() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return useCallback(() => {
    toast
      .promise(
        apiClient.docs.create({
          title: t('Untitled'),
        }),
        {
          loading: t('Creating file'),
          success: t('File created'),
          error: t('Error creating file'),
        },
      )
      .then(({ id }) => {
        navigate(RoutePath.FilesById.replace(':id', id))
      })
  }, [t, navigate])
}
