import React, { FC, MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

export const ContentTryButton: FC<{ compact?: boolean; onClick?: MouseEventHandler }> = ({ compact, onClick }) => {
  const { t } = useTranslation()

  return (
    <button
      className={clsx(
        'bg-blue-600 font-medium text-white hover:bg-blue-700 active:bg-blue-800',
        compact ? 'rounded-md px-6 py-2' : 'rounded-xl px-8 py-4 text-xl',
      )}
      onClick={onClick}
    >
      {t('Try for free')}
    </button>
  )
}
