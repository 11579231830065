import { MutableRefObject } from 'react'
import { isHotkeyPressed } from './isHotkeyPressed'
import { HotkeyConfig } from './Hotkey'

export class HotkeysController {
  private configs = new Set<MutableRefObject<HotkeyConfig>>()

  add(config: MutableRefObject<HotkeyConfig>) {
    this.configs.add(config)

    if (this.configs.size === 1) {
      this.watch()
    }
  }

  delete(config: MutableRefObject<HotkeyConfig>) {
    this.configs.delete(config)

    if (this.configs.size === 0) {
      this.unwatch()
    }
  }

  private watch() {
    window.addEventListener('keydown', this.callback)
  }

  private unwatch() {
    window.removeEventListener('keydown', this.callback)
  }

  private callback = (event: KeyboardEvent) => {
    for (const config of this.configs) {
      if (isHotkeyPressed(event, config.current)) {
        if (config.current.stop) {
          event.stopPropagation()
        }

        if (config.current.prevent) {
          event.preventDefault()
        }

        config.current.fn(event)

        return
      }
    }
  }
}
