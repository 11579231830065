import { makeAutoObservable } from 'mobx'
import { CalcDoc } from '../doc/CalcDoc'
import { BBox } from '../../common/BBox'
import { Line } from '../../common/Line'
import { CalcMemberElements } from './CalcMemberElements'
import { CalcMember } from '../doc/CalcMember'
import { Point } from '../../common/Point'

export class CalcMembersIntersections {
  private draggingBBox: BBox | null = null
  private draggingMembers: CalcMember[] | null = null

  constructor(
    private doc: CalcDoc,
    private elements: CalcMemberElements,
  ) {
    makeAutoObservable(this)
  }

  get lines(): Line[] {
    const lines: Line[] = []

    this.doc.visibleMembers.forEach((member) => {
      const element = this.elements.get(member)
      const distance = 5

      if (element && this.draggingBBox) {
        if (Math.abs(element.offsetLeft - this.draggingBBox[0].x) < distance) {
          lines.push({
            x: element.offsetLeft,
          })
        }
      }
    })

    return lines
  }

  setDraggingMembers(members: CalcMember[] | null): void {
    this.draggingMembers = members

    if (members === null) {
      this.draggingBBox = null
      return
    }

    const points: Point[] = []

    members.forEach((member) => {
      const element = this.elements.get(member)

      if (element) {
        points.push({
          x: element.offsetLeft,
          y: element.offsetTop,
        })

        points.push({
          x: element.offsetLeft + element.clientWidth,
          y: element.offsetTop + element.clientHeight,
        })
      }
    })

    this.draggingBBox = BBox.fromPoints(points)
  }

  onDragMove(delta: Point): void {
    if (this.draggingBBox) {
      this.draggingBBox = [
        {
          x: this.draggingBBox[0].x + delta.x,
          y: this.draggingBBox[0].x + delta.y,
        },
        {
          x: this.draggingBBox[1].x + delta.x,
          y: this.draggingBBox[1].x + delta.y,
        },
      ]
    }
  }
}
