import { useContext, useMemo } from 'react'
import { CalcContext } from './context/CalcContext'
import { CalcMember } from './doc/CalcMember'

export function useCalcMembers(memberIds: string[]) {
  const { doc } = useContext(CalcContext)

  return useMemo(
    () =>
      memberIds.reduce((members: CalcMember[], memberId) => {
        const member = doc.getMemberById(memberId)

        if (member) {
          members.push(member)
        }

        return members
      }, []),
    [memberIds, doc],
  )
}
