import React, { DispatchWithoutAction, FC, useContext } from 'react'
import { CalcMemberViewContext } from './CalcMemberViewContext'
import { observer } from 'mobx-react-lite'

interface Props {
  odEdit?: DispatchWithoutAction
}

export const CalcMemberCaptionValue: FC<Props> = observer(({ odEdit }) => {
  const { member } = useContext(CalcMemberViewContext)

  return (
    <div
      className="flex max-w-[120px] items-center justify-center break-words text-center text-xs text-neutral-500"
      onDoubleClick={() => {
        odEdit?.()
      }}
    >
      {member.caption}
    </div>
  )
})
