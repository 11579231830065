import React, { FC, HTMLAttributes } from 'react'
import classNames from 'classnames'

export const HeaderContainer: FC<HTMLAttributes<HTMLDivElement>> = ({ className, children, ...attrs }) => {
  return (
    <div
      className={classNames(
        'flex h-12 w-full items-center justify-between bg-neutral-800 text-xs text-white shadow',
        className,
      )}
      {...attrs}
      tabIndex={-1}
    >
      {children}
    </div>
  )
}
