import axios, { AxiosError, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios'
import { AxiosProvider } from './AxiosProvider'
import { ApiTokenFactory } from './ApiTokenFactory'
import { RoutePath } from '../common/RoutePath'
import { router } from '../router'
import { ApiClientDocs } from './docs/ApiClientDocs'
import { ApiClientAuth } from './auth/ApiClientAuth'

export class ApiClient implements AxiosProvider {
  instance = axios.create({
    baseURL: 'http://localhost:3001/',
  })

  docs = new ApiClientDocs(this)
  auth = new ApiClientAuth(this)

  private tokenFactory = new ApiTokenFactory(this.auth)

  constructor() {
    this.instance.interceptors.request.use((config) => this.addToken(config))
    this.instance.interceptors.response.use(null, (error: AxiosError) => this.onError(error))
  }

  async request<T>(config: AxiosRequestConfig): Promise<T> {
    return this.instance.request<T>(config).then((response) => response.data)
  }

  private async addToken(config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> {
    const token = await this.tokenFactory.getFreshAccessToken()

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  }

  private async onError(error: AxiosError) {
    if (error.response?.status === 401) {
      return this.onUnauthorized()
    }

    return Promise.reject(error)
  }

  private onUnauthorized() {
    return router.navigate(RoutePath.Login)
  }
}

export const apiClient = new ApiClient()
