import React, { Dispatch, FC, useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { onMouseMoveUntilUp } from '../common/functions/onMouseMoveUntilUp'
import { isMoveThresholdOvercome } from '../common/functions/isMoveThresholdOvercome'
import { CalcContext } from './context/CalcContext'
import { Point } from '../common/Point'
import classNames from 'classnames'

export const CalcCanvas: FC = observer(() => {
  const { doc, selection, newMember, scale } = useContext(CalcContext)
  const [shiftDown, setShiftDown] = useState(false)

  useEffect(() => {
    const keydown = (event: KeyboardEvent) => {
      if (event.key === ' ') {
        setShiftDown(true)

        window.addEventListener('keyup', keyup)
      }
    }

    const keyup = (event: KeyboardEvent) => {
      if (event.key === ' ') {
        setShiftDown(false)

        window.removeEventListener('keyup', keyup)
      }
    }

    window.addEventListener('keydown', keydown)

    return () => {
      window.removeEventListener('keydown', keydown)
      window.removeEventListener('keyup', keyup)
    }
  }, [])

  return (
    <div
      className={classNames('fixed bottom-0 left-0 right-0 top-12', shiftDown ? 'cursor-grab' : 'cursor-text')}
      tabIndex={-1}
      onMouseDown={(down) => {
        let onMove: Dispatch<Point> | undefined

        if (shiftDown || down.button === 1) {
          down.preventDefault()

          const initial = scale.zero

          onMouseMoveUntilUp((move) => {
            scale.setZero({
              x: initial.x + down.clientX - move.clientX,
              y: initial.y + down.clientY - move.clientY,
            })
          }).then()
        } else {
          onMouseMoveUntilUp((move) => {
            move.preventDefault()

            if (!onMove && isMoveThresholdOvercome(down, move)) {
              onMove = selection.cursor.begin([Point.fromEvent(down), Point.fromEvent(move)])
            }

            onMove?.(Point.fromEvent(move))
          }).then(() => {
            if (onMove) {
              selection.cursor.finish()
            } else {
              selection.deselect()
              newMember.create(Point.fromEvent(down))
            }
          })
        }
      }}
      onContextMenu={(event) => {
        event.preventDefault()
        event.stopPropagation()

        doc.addNote(Point.fromEvent(event))
      }}
    />
  )
})
