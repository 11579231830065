import React, { FC } from 'react'
import { CalcHeaderButton } from '../../calc/header/common/CalcHeaderButton'
import { CalcHeaderChevron } from '../../calc/header/common/CalcHeaderChevron'

export const HeaderUser: FC = () => {
  return (
    <CalcHeaderButton>
      Alex Chuev <CalcHeaderChevron />
    </CalcHeaderButton>
  )
}
