import React, { Dispatch, DispatchWithoutAction, FC } from 'react'
import AutosizeInput from 'react-input-autosize'
import clsx from 'clsx'

export const LandingExpressionInput: FC<{
  expression: string
  setExpression: Dispatch<string>
  autoFocus?: boolean
  onBlur?: DispatchWithoutAction
}> = ({ expression, setExpression, autoFocus, onBlur }) => {
  return (
    <AutosizeInput
      className="mb-1.5 font-normal"
      value={expression}
      autoFocus={autoFocus}
      onChange={(event) => {
        setExpression(event.target.value)
      }}
      onBlur={onBlur}
      inputClassName={clsx('rounded ring ring-blue-500 ring-2 p-1 text-center shadow-inner')}
    />
  )
}
