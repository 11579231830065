import { CalcActionsHistory, CalcHistoryAction } from './CalcActionsHistory'
import { apiClient } from '../../api/ApiClient'
import { CalcDoc } from '../doc/CalcDoc'
import toast from 'react-hot-toast'
import { t } from '../../i18n'
import { delay } from '../../common/functions/delay'

export class CalcPersistence {
  private savedAction: CalcHistoryAction | null = null

  constructor(
    private doc: CalcDoc,
    private history: CalcActionsHistory,
  ) {}

  areUnsavedChanges(): boolean {
    return this.history.currentAction !== this.savedAction
  }

  async saveIfNeeded(): Promise<void> {
    if (this.areUnsavedChanges()) {
      await this.save()
    } else {
      toast.success(t('Everything is already saved'))
    }
  }

  private async save() {
    const id = toast.loading(t('Saving the file...'))

    try {
      await delay(750)

      await apiClient.docs.update({
        doc: this.doc,
      })

      this.savedAction = this.history.currentAction

      toast.success(t('The file has been saved'))
    } catch (e) {
      toast.error(t('Error saving the file'))
    }

    toast.remove(id)
  }
}
