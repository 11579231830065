import React, { FC, useContext } from 'react'
import { Hotkey } from '../common/hotkeys/Hotkey'
import { CalcContext } from './context/CalcContext'
import { CalcOperationType } from './operations/CalcOperationType'

export const CalcHotkeys: FC = () => {
  const { selection, clipboard, history, newMember, persistence } = useContext(CalcContext)
  const { operations } = selection

  return (
    <>
      <Hotkey k="s" mod fn={() => persistence.saveIfNeeded()} prevent />

      <Hotkey k="c" mod fn={() => clipboard.copySelected()} />
      <Hotkey k="v" mod fn={() => clipboard.pasteCopied()} />

      <Hotkey k="z" mod fn={() => history.undo()} />
      <Hotkey k="z" mod shift fn={() => history.redo()} />

      <Hotkey digit fn={() => newMember.create()} />

      <Hotkey k="g" mod fn={() => selection.arrange.cluster()} prevent stop />
      <Hotkey k="d" mod fn={() => selection.generation.duplicate()} prevent />
      <Hotkey k="h" mod shift fn={() => selection.visibility.hide()} />
      <Hotkey k="a" mod fn={() => selection.selectAllVisible()} prevent />
      <Hotkey esc fn={() => selection.deselect()} />
      <Hotkey del fn={() => selection.destruction.remove()} />

      <Hotkey up shift fn={() => selection.move.up()} />
      <Hotkey right shift fn={() => selection.move.right()} />
      <Hotkey down shift fn={() => selection.move.down()} />
      <Hotkey left shift fn={() => selection.move.left()} />

      <Hotkey up fn={() => selection.navigate.up()} />
      <Hotkey right fn={() => selection.navigate.right()} />
      <Hotkey down fn={() => selection.navigate.down()} />
      <Hotkey left fn={() => selection.navigate.left()} />

      <Hotkey k="+" fn={() => operations.createRelation(CalcOperationType.Sum)} />
      <Hotkey k="-" fn={() => operations.createRelation(CalcOperationType.Subtraction)} />
      <Hotkey k="*" fn={() => operations.createRelation(CalcOperationType.Multiplication)} />
      <Hotkey k="*" shift fn={() => operations.createRelation(CalcOperationType.Multiplication)} />
      <Hotkey k="/" fn={() => operations.createRelation(CalcOperationType.Division)} />
      <Hotkey k="^" fn={() => operations.createRelation(CalcOperationType.Exponentiation)} />
      <Hotkey k="%" fn={() => operations.createRelation(CalcOperationType.DivisionRemainder)} />

      <Hotkey k="\" fn={() => operations.destruction()} />
    </>
  )
}
