import { makeAutoObservable } from 'mobx'
import { ICalcSelection } from './ICalcSelection'

export class CalcSelectionVisibility {
  constructor(private selection: ICalcSelection) {
    makeAutoObservable(this)
  }

  hide() {
    this.selection.members.forEach((member) => member.setHidden(true))
    this.selection.deselect()
  }
}
