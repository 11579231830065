import { makeAutoObservable } from 'mobx'
import { Cloneable } from '../../common/Cloneable'
import { CalcOperationType } from '../operations/CalcOperationType'
import { calcOperationSymbols } from '../operations/calcOperationSymbols'
import { CalcMember } from './CalcMember'
import { evaluate } from 'mathjs'
import { CalcMemberExpressionNormalizer } from './CalcMemberExpressionNormalizer'
import { ApiDocMember } from '../../api/docs/ApiDoc'
import { CalcDoc } from './CalcDoc'
import { MemberValueFactory } from './MemberValueFactory'

export class CalcMemberExpression implements Cloneable<CalcMemberExpression> {
  constructor(
    public valueFactory: MemberValueFactory,
    public value = '',
    public memberIds: string[] = [],
  ) {
    makeAutoObservable(this)
  }

  get result(): number {
    try {
      const value = this.evaluate()

      if (Number.isFinite(value)) {
        // @ts-ignore
        return value as number
      }
    } catch (e) {}

    return 0
  }

  get valid(): boolean {
    try {
      return Number.isFinite(this.evaluate())
    } catch (e) {
      return false
    }
  }

  static fromTuple(doc: CalcDoc, expression: ApiDocMember['expression']): CalcMemberExpression {
    if (Array.isArray(expression)) {
      const [value, ...memberIds] = expression

      return new CalcMemberExpression(doc, value, memberIds)
    }

    return new CalcMemberExpression(doc)
  }

  static fromOperation(doc: CalcDoc, members: CalcMember[], type: CalcOperationType) {
    return new CalcMemberExpression(
      doc,
      members.map((_, index) => CalcMemberExpression.getScopeVariable(index)).join(calcOperationSymbols[type]),
      members.map((member) => member.id),
    )
  }

  static getScopeVariable(index: number): string {
    return `$${this.getScopeVariableNumber(index)}`
  }

  static getScopeVariableNumber(index: number): number {
    return index + 1
  }

  normalize() {
    new CalcMemberExpressionNormalizer(this).normalize()
  }

  setValue(value: string) {
    this.value = value
  }

  addMember(member: CalcMember, type: CalcOperationType) {
    this.value = `${this.value}${calcOperationSymbols[type]}$${this.memberIds.length + 1}`
    this.memberIds.push(member.id)
  }

  clone() {
    return new CalcMemberExpression(this.valueFactory, this.value, this.memberIds.slice())
  }

  private evaluate(): any {
    const scope = this.memberIds.reduce(
      (scope, memberId, index) =>
        scope.set(CalcMemberExpression.getScopeVariable(index), this.valueFactory.getMemberValue(memberId)),
      new Map<string, number>(),
    )

    return evaluate(this.value, scope)
  }
}
