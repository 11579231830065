import React, { FC, useEffect, useState } from 'react'
import { LandingExampleWrapper } from './LandingExampleWrapper'
import { LandingExpressionView } from './LandingExpressionView'
import { LandingExpressionInput } from './LandingExpressionInput'
import { safeEvaluate } from '../common/functions/safeEvaluate'

export const LandingExampleExpression: FC<{ initialExpression: string }> = ({ initialExpression }) => {
  const [expression, setExpression] = useState(initialExpression)
  const [value, setValue] = useState(() => safeEvaluate(initialExpression))

  useEffect(() => {
    const value = safeEvaluate(expression)
    typeof value === 'number' && setValue(value)
  }, [expression])

  return (
    <LandingExampleWrapper>
      <LandingExpressionView
        title="Expression"
        value={
          <LandingExpressionInput
            expression={expression}
            setExpression={setExpression}
            onBlur={() => {
              if (!expression) {
                setExpression(initialExpression)
              }
            }}
          />
        }
      />
      <LandingExpressionView title="Result" value={value} bold />
    </LandingExampleWrapper>
  )
}
