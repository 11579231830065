import React from 'react'
import { Toaster } from 'react-hot-toast'
import { RouterProvider } from 'react-router-dom'
import { router } from './router'

function App() {
  return (
    <>
      <RouterProvider router={router} />

      <Toaster
        position="bottom-center"
        toastOptions={{
          style: {
            color: 'black',
            borderRadius: 6,
            padding: '6px 12px',
            fontSize: 15,
          },
        }}
      />
    </>
  )
}

export default App
