import { CalcMember } from '../../doc/CalcMember'
import { CalcMemberElements } from '../CalcMemberElements'
import { CalcActionsHistory } from '../CalcActionsHistory'
import { HARDCODED_MEMBER_HEIGHT, HARDCODED_MEMBER_WIDTH, MEMBERS_GAP } from '../../../constants'
import { Point } from '../../../common/Point'
import { without } from 'lodash'

export class CalcMembersArrangeClusterer {
  constructor(
    private elements: CalcMemberElements,
    private history: CalcActionsHistory,
  ) {}

  clusterMembers(members: CalcMember[]) {
    if (members.length < 2) {
      return
    }

    const initialPositions = this.getCurrentMemberPositions(members)

    this.rearrangeGrid(this.createMembersGrid(members.slice()))

    const updatedPositions = this.getCurrentMemberPositions(members)

    this.history.add({
      undo() {
        initialPositions.forEach(([member, position]) => member.setPosition(position))
      },
      redo() {
        updatedPositions.forEach(([member, position]) => member.setPosition(position))
      },
    })
  }

  private getCurrentMemberPositions(members: CalcMember[]): [CalcMember, Point][] {
    return members.map((member) => [member, member.position])
  }

  private rearrangeGrid(grid: CalcMember[][]) {
    const first = grid[0][0]
    let x = first.position.x - this.getElementWidth(first) / 2
    let y = first.position.y

    grid.forEach((row) => {
      let rowX = x
      let rowHeight = 0

      row.forEach((member) => {
        const width = this.getElementWidth(member)
        const height = this.getElementHeight(member)

        member.setPosition({
          x: rowX + width / 2,
          y,
        })

        if (height > rowHeight) {
          rowHeight = height
        }

        rowX += width + MEMBERS_GAP
      })

      y += rowHeight + MEMBERS_GAP
    })
  }

  private createMembersGrid(members: CalcMember[]): CalcMember[][] {
    const singleRelationCluster = this.checkSingleRelationCluster(members)

    if (singleRelationCluster) {
      members = singleRelationCluster[1]
    }

    const rowSize = this.getRowSize(members)
    const membersByY = this.sortBy(members, 'y')
    const grid: CalcMember[][] = []

    while (membersByY.length) {
      const row = membersByY.splice(0, rowSize)

      grid.push(this.sortBy(row, 'x'))
    }

    if (singleRelationCluster) {
      const lastRow = grid[grid.length - 1]

      if (lastRow.length === rowSize) {
        grid.push([singleRelationCluster[0]])
      } else {
        lastRow.push(singleRelationCluster[0])
      }
    }

    return grid
  }

  private checkSingleRelationCluster(selectedMembers: CalcMember[]): [CalcMember, CalcMember[]] | false {
    const selectedMembersSet = new Set(selectedMembers.map((member) => member.id))

    for (let selectedMember of selectedMembers) {
      if (
        selectedMember.relation &&
        selectedMember.expression.memberIds.length === selectedMembers.length - 1 &&
        selectedMember.expression.memberIds.every(
          (expressionMember) => expressionMember && selectedMembersSet.has(expressionMember),
        )
      ) {
        return [selectedMember, without(selectedMembers, selectedMember)]
      }
    }

    return false
  }

  private getElementWidth(member: CalcMember): number {
    const element = this.elements.get(member)

    return element ? element.clientWidth : HARDCODED_MEMBER_WIDTH
  }

  private getElementHeight(member: CalcMember): number {
    const element = this.elements.get(member)

    return element ? element.clientHeight : HARDCODED_MEMBER_HEIGHT
  }

  private sortBy(members: CalcMember[], key: keyof Point): CalcMember[] {
    return members.sort((member, other) => member.position[key] - other.position[key])
  }

  private getRowSize(members: CalcMember[]) {
    return Math.ceil(Math.sqrt(members.length))
  }
}
