import React, { FC } from 'react'
import { HeaderContainer } from '../../calc/header/HeaderContainer'
import { DocsHeaderAddButton } from './DocsHeaderAddButton'
import { CalcHeaderChevron } from '../../calc/header/common/CalcHeaderChevron'
import { HeaderUser } from './HeaderUser'
import { CalcHeaderButton } from '../../calc/header/common/CalcHeaderButton'
import { Logo } from './Logo'

export const DocsHeader: FC = () => {
  return (
    <HeaderContainer>
      <CalcHeaderButton>
        <Logo strokeWidth={1.25} className="w-5" />

        <CalcHeaderChevron />
      </CalcHeaderButton>

      <DocsHeaderAddButton />

      <HeaderUser />
    </HeaderContainer>
  )
}
