import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { ApiListDoc } from '../../api/docs/ApiListDoc'
import { useTranslation } from 'react-i18next'
import { runInAction } from 'mobx'
import { apiClient } from '../../api/ApiClient'

export const DocsListDocRemoved: FC<{
  doc: ApiListDoc
}> = observer(({ doc }) => {
  const { t } = useTranslation()

  return (
    <div className="flex w-full items-center justify-between gap-1.5 p-1.5">
      <div className="text-neutral-400 line-through">{doc.title}</div>

      <button
        className="flex h-7 items-center gap-2 rounded bg-white px-2 text-sm shadow hover:bg-white active:bg-neutral-50"
        onClick={() => {
          runInAction(() => {
            doc.deleted = false
          })

          apiClient.docs
            .patch(doc.id, {
              deleted: false,
            })
            .catch(() => {
              runInAction(() => {
                doc.deleted = true
              })
            })
        }}
      >
        {t('Restore')}
      </button>
    </div>
  )
})
