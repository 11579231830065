import React, { FC, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { CalcContext } from '../context/CalcContext'
import { CalcExpressionMemberView } from './CalcExpressionMemberView'
import { CalcRelationMemberView } from './CalcRelationMemberView'

export const CalcMembers: FC = observer(() => {
  const { doc, hiddenShown } = useContext(CalcContext)

  return (
    <>
      {(hiddenShown ? doc.members : doc.visibleMembers).map((member) =>
        member.relation ? (
          <CalcRelationMemberView member={member} key={member.id} />
        ) : (
          <CalcExpressionMemberView member={member} key={member.id} />
        ),
      )}
    </>
  )
})
