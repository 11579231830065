import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { apiClient } from '../../api/ApiClient'
import { AsyncState, PromiseFn, useAsync } from 'react-async'
import { ApiDocsListResponse } from '../../api/docs/ApiDocsListResponse'
import { DocsList } from './DocsList'
import { DocsListTitle } from './DocsListTitle'
import { DocsListEmpty } from './DocsListEmpty'
import { DocsListStateContextProvider } from './DocsListStateContext'

const getDocs: PromiseFn<ApiDocsListResponse> = (_, controller: AbortController) =>
  apiClient.docs.list(controller.signal)

export const DocsListController: FC = () => {
  const { t } = useTranslation()
  const state: AsyncState<ApiDocsListResponse> = useAsync({ promiseFn: getDocs })

  if (state.error) {
    return <DocsListTitle>{t('Error loading...')}</DocsListTitle>
  }

  if (state.data && state.data.docs.length) {
    return (
      <DocsListStateContextProvider docs={state.data.docs}>
        <DocsList />
      </DocsListStateContextProvider>
    )
  }

  if (state.data) {
    return <DocsListEmpty />
  }

  return <DocsListTitle>{t('Loading...')}</DocsListTitle>
}
