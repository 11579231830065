import { ButtonHTMLAttributes, FC } from 'react'
import classNames from 'classnames'

export const DocsListDocButton: FC<
  ButtonHTMLAttributes<HTMLButtonElement> & {
    active?: boolean
  }
> = ({ className, children, active, ...attrs }) => {
  return (
    <button
      className={classNames(
        'disabled:hover::bg-transparent disabled:active::bg-transparent rounded p-1.5 disabled:bg-transparent',
        active ? 'bg-white' : 'hover:bg-black/5 active:bg-black/10',
        className,
      )}
      {...attrs}
    >
      {children}
    </button>
  )
}
