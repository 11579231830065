import React, { FC, MouseEventHandler } from 'react'
import { ContentMenu } from './ContentMenu'
import { ContentLogo } from './ContentLogo'

export const ContentNav: FC<{ onClick?: MouseEventHandler }> = ({ onClick }) => {
  return (
    <nav className="flex items-center justify-between gap-6 py-8">
      <ContentLogo />

      <ContentMenu onClick={onClick} />
    </nav>
  )
}
