import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { CalcMember } from '../doc/CalcMember'
import { CalcExpressionMember } from './CalcExpressionMember'
import { useCalcMembers } from '../useCalcMembers'

export const CalcExpressionMembers: FC<{ member: CalcMember }> = observer(({ member }) => {
  const expressionMembers = useCalcMembers(member.expression.memberIds)

  return (
    <>
      {expressionMembers.map((expressionMember, index) => (
        <CalcExpressionMember
          member={member}
          expressionMember={expressionMember}
          index={index}
          key={member.id + '_' + expressionMember}
        />
      ))}
    </>
  )
})
