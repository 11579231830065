import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { ContentNav } from '../common/content/ContentNav'
import { ContentContainer } from '../common/content/ContentContainer'
import { useTranslation } from 'react-i18next'
import { LandingExampleExpression } from './LandingExampleExpression'
import { LandingFeature } from './LandingFeature'
import { ContentTryButton } from '../common/content/ContentTryButton'
import { ContentFooter } from '../common/content/ContentFooter'
import { LandingExampleMembers } from './LandingExampleMembers'
import { LandingExampleSync } from './LandingExampleSync'
import { LandingVideo } from './LandingVideo'
import appstore from './images/appstore.png'
import googleplay from './images/googleplay.png'
import windows from './images/windows.png'

export const Landing: FC = () => {
  const { t } = useTranslation()
  const openSubscription = () => {
    window.alert(
      t(
        'We are still working on the main functionality. Please come back within a couple of weeks or write to alex@calc.ing.',
      ),
    )
  }

  return (
    <>
      <Helmet>
        <title>{t('Simple math calculator in the form of a connected graph')}</title>
      </Helmet>

      <ContentContainer>
        <ContentNav onClick={openSubscription} />
      </ContentContainer>

      <ContentContainer className="py-10">
        <h1 className="mb-10 text-5xl font-black tracking-tighter sm:text-6xl">
          {t('Your calculations gain history')}
        </h1>

        <ContentTryButton onClick={openSubscription} />

        <LandingVideo onClick={openSubscription} />

        <div className="mt-10 flex items-center justify-center gap-3">
          <button className="flex active:translate-y-px" onClick={openSubscription}>
            <img src={appstore} alt="" width={180} height={52} />
          </button>

          <button className="flex active:translate-y-px" onClick={openSubscription}>
            <img src={googleplay} alt="" width={180} height={52} />
          </button>

          <button className="flex active:translate-y-px" onClick={openSubscription}>
            <img src={windows} alt="" width={180} height={52} />
          </button>
        </div>
      </ContentContainer>

      <LandingFeature
        title={t('Expressions')}
        description={t(
          `Each value can be complex in itself and consist of a mathematical expression. You can even use parentheses. All operations are calculated according to the rules of mathematics.`,
        )}
        example={<LandingExampleExpression initialExpression="4200 - 10%" />}
        shade
      />

      <LandingFeature
        title={t('Members')}
        description={t(
          `Each individual value can be used in another expression as a variable to calculate the next value. The values are automatically recalculated if any of them change.`,
        )}
        example={<LandingExampleMembers />}
      />

      <LandingFeature
        title={t('Functions')}
        description={t(
          `Various functions are available to process the values. For example, when you need to round a value to a decimal place or calculate sine or cosine. A list of them can be found in guides.`,
        )}
        example={<LandingExampleExpression initialExpression="round(4200 - 10%)" />}
        shade
      />

      <LandingFeature
        title={t('Synchronization')}
        description={t(
          `When you work from different devices, your changes sync seamlessly and all calculations stay up to date. Even if you are offline for some time, after the Internet appears, the data will be securely saved.`,
        )}
        example={<LandingExampleSync />}
      />

      <LandingFeature
        title={t('Feedback')}
        description={t(
          `We are extremely open to feedback and constructive criticism in order to make the product the best it can be. If you have even small comments or want to share your experience of using it, please write to alex@calc.ing.`,
        )}
        shade
      />

      <ContentFooter onClick={openSubscription} />
    </>
  )
}
