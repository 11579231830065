import { makeAutoObservable } from 'mobx'
import { Point } from '../../../common/Point'
import { ICalcSelection } from './ICalcSelection'

export class CalcSelectionMove {
  constructor(private selection: ICalcSelection) {
    makeAutoObservable(this)
  }

  left() {
    this.move(Point.fromValues(-50, 0))
  }

  right() {
    this.move(Point.fromValues(50, 0))
  }

  up() {
    this.move(Point.fromValues(0, -50))
  }

  down() {
    this.move(Point.fromValues(0, 50))
  }

  private move(shift: Point) {
    this.selection.members.forEach((member) => member.setPosition(Point.shift(member.position, shift)))
  }
}
