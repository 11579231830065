import { makeAutoObservable } from 'mobx'
import { CalcDoc } from '../../doc/CalcDoc'
import { ICalcSelection } from './ICalcSelection'
import { CalcMemberElements } from '../CalcMemberElements'
import { CalcMember } from '../../doc/CalcMember'
import { Point } from '../../../common/Point'
import { MEMBERS_GAP } from '../../../constants'

export class CalcSelectionGeneration {
  constructor(
    private doc: CalcDoc,
    private selection: ICalcSelection,
    private elements: CalcMemberElements,
  ) {
    makeAutoObservable(this)
  }

  duplicate() {
    if (this.selection.members.length) {
      const shift = this.getShift(this.selection.members)
      const members = this.selection.members.map((member) => {
        const clone = member.clone()
        clone.position = Point.shift(clone.position, shift)
        return clone
      })

      if (this.doc.addMembers(members)) {
        this.selection.setMembers(members)
      }
    }
  }

  private getShift(members: CalcMember[]): Point {
    return members.length === 1 ? this.getSingleShift(members[0]) : Point.fromValue(15)
  }

  private getSingleShift(member: CalcMember): Point {
    const element = this.elements.get(member)

    if (element) {
      return {
        x: element.clientWidth + MEMBERS_GAP,
        y: 0,
      }
    }

    return Point.fromValue(15)
  }
}
