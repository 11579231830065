import React, { FC, PropsWithChildren } from 'react'
import { CalcHeaderMenu, CalcHeaderMenuItem } from './CalcHeaderMenu'
import { CalcHeaderDropdownButton } from './CalcHeaderDropdownButton'

export const CalcHeaderDropdownMenuButton: FC<
  PropsWithChildren<{
    items: Array<CalcHeaderMenuItem | null>
    direction?: 'left' | 'right'
    dropdownClassName?: string
  }>
> = ({ items, direction, children, dropdownClassName }) => {
  return (
    <CalcHeaderDropdownButton
      content={<CalcHeaderMenu items={items} />}
      direction={direction}
      dropdownClassName={dropdownClassName}
    >
      {children}
    </CalcHeaderDropdownButton>
  )
}
