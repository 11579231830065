import React, { DispatchWithoutAction, FC, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { CalcMemberViewContext } from './CalcMemberViewContext'
import classNames from 'classnames'

export const CalcMemberExpressionValue: FC<{ onEdit: DispatchWithoutAction; className?: string }> = observer(
  ({ onEdit, className }) => {
    const { member } = useContext(CalcMemberViewContext)

    return (
      <div
        className={classNames('flex h-7 items-center whitespace-nowrap text-2xl', className)}
        onDoubleClick={(event) => {
          event.preventDefault()
          onEdit()
        }}
      >
        {member.roundedValue}
      </div>
    )
  },
)
