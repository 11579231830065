import React, { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useMemo, useState } from 'react'
import { CalcDoc } from '../doc/CalcDoc'
import { CalcActionsHistory } from './CalcActionsHistory'
import { CalcSelection } from './selection/CalcSelection'
import { CalcScale } from './CalcScale'
import { CalcClipboard } from './CalcClipboard'
import { CalcNewMember } from './CalcNewMember'
import { CalcMemberElements } from './CalcMemberElements'
import { CalcPersistence } from './CalcPersistence'
import { CalcMembersIntersections } from './CalcMembersIntersections'
import { useCalcDoc } from './useCalcDoc'

interface CalcContextValue {
  doc: CalcDoc
  history: CalcActionsHistory
  persistence: CalcPersistence
  selection: CalcSelection
  scale: CalcScale
  clipboard: CalcClipboard
  newMember: CalcNewMember
  intersections: CalcMembersIntersections
  elements: CalcMemberElements
  hiddenShown: boolean
  setHiddenShown: Dispatch<SetStateAction<boolean>>
}

export const CalcContext = createContext({} as CalcContextValue)

function useCalcContextValue(): CalcContextValue {
  const doc = useCalcDoc()
  const history = useMemo(() => new CalcActionsHistory(), [])
  const persistence = useMemo(() => new CalcPersistence(doc, history), [doc, history])
  const elements = useMemo(() => new CalcMemberElements(), [])
  const intersections = useMemo(() => new CalcMembersIntersections(doc, elements), [doc, elements])
  const selection = useMemo(() => new CalcSelection(doc, elements, history), [doc, elements, history])
  const scale = useMemo(() => new CalcScale(), [])
  const clipboard = useMemo(() => new CalcClipboard(doc, selection), [doc, selection])
  const newMember = useMemo(() => new CalcNewMember(doc), [doc])
  const [hiddenShown, setHiddenShown] = useState(false)

  return {
    doc,
    history,
    persistence,
    selection,
    intersections,
    scale,
    clipboard,
    newMember,
    elements,
    hiddenShown,
    setHiddenShown,
  }
}

export const CalcContextProvider: FC<PropsWithChildren> = ({ children }) => (
  <CalcContext.Provider value={useCalcContextValue()}>{children}</CalcContext.Provider>
)
