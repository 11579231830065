import React, { FC, useContext } from 'react'
import { CalcContext } from '../context/CalcContext'
import { CalcSingleNote } from './CalcSingleNote'
import { observer } from 'mobx-react-lite'

export const CalcNotes: FC = observer(() => {
  const { doc } = useContext(CalcContext)

  return (
    <>
      {doc.notes.map((note) => (
        <CalcSingleNote note={note} key={note.id} />
      ))}
    </>
  )
})
