import React, { FC, KeyboardEvent, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { CalcMemberExpressionInput } from './CalcMemberExpressionInput'
import { CalcMemberExpressionValue } from './CalcMemberExpressionValue'
import { CalcContext } from '../context/CalcContext'
import { CalcMember } from '../doc/CalcMember'

export const CalcMemberExpression: FC<{
  member: CalcMember
}> = observer(({ member }) => {
  const { selection } = useContext(CalcContext)

  return member.valueEditing ? (
    <CalcMemberExpressionInput
      expression={member.expression}
      onKeyDown={(event: KeyboardEvent) => {
        if (event.key === 'Tab') {
          event.preventDefault()

          member.setValueEditing(false)
          member.setCaptionEditing(true)
        }
      }}
      onCancel={() => {
        member.setValueEditing(false)
      }}
      onFinish={(expression) => {
        member.setExpression(expression)
        member.setValueEditing(false)

        if (!member.caption) {
          member.setCaptionEditing(true)
        }
      }}
    />
  ) : (
    <CalcMemberExpressionValue
      onEdit={() => {
        selection.deselect()
        member.setValueEditing(true)
      }}
    />
  )
})
