import React, { FC, SVGAttributes } from 'react'

export const Logo: FC<SVGAttributes<SVGElement>> = (attrs) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...attrs}>
      <rect x="13.5" y="13.5" width="8" height="8" rx="1.5" stroke="white" />
      <path d="M16.8679 20V15H18.1321V20H16.8679ZM15 18.1321V16.8679H20V18.1321H15Z" fill="white" />
      <rect x="13.5" y="2.5" width="8" height="8" rx="1.5" stroke="white" />
      <rect x="2.5" y="2.5" width="8" height="8" rx="1.5" stroke="white" />
      <rect x="2.5" y="13.5" width="8" height="8" rx="1.5" stroke="white" />
    </svg>
  )
}
