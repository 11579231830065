import { makeAutoObservable } from 'mobx'
import { Cloneable } from '../../common/Cloneable'
import { Point } from '../../common/Point'
import { round } from 'lodash'
import { CalcMemberExpression } from './CalcMemberExpression'
import { calcPatchStorage } from './CalcPatchStorage'
import { CalcMemberPatch } from './CalcMemberPatch'
import { generateMemberId } from '../functions/generateMemberId'
import { CalcDocIdProvider } from './CalcDocIdProvider'
import { MemberValueFactory } from './MemberValueFactory'

export class CalcMember implements Cloneable<CalcMember> {
  captionEditing = false
  valueEditing = false
  hidden = false
  deleted = false
  caption = ''

  constructor(
    public doc: CalcDocIdProvider & MemberValueFactory,
    public id: string, // to be able to restore id after serialization
    public position: Point, // todo: should be some intermediate position to not create patches on drag
    public expression = new CalcMemberExpression(doc),
  ) {
    makeAutoObservable(this)
  }

  get roundedValue(): number {
    return round(this.value, 2)
  }

  get value(): number {
    return this.expression.result
  }

  get relation(): boolean {
    return this.expression.memberIds.length > 0
  }

  setExpression(expression: CalcMemberExpression) {
    this.storePatch({
      expression: [expression.value, ...expression.memberIds],
    })

    this.expression = expression
  }

  setDeleted(deleted: boolean) {
    this.storePatch({
      deleted,
    })

    this.deleted = deleted
  }

  setHidden(hidden: boolean) {
    this.storePatch({
      hidden,
    })

    this.hidden = hidden
  }

  setValueEditing(value: boolean) {
    this.valueEditing = value
  }

  setCaptionEditing(value: boolean) {
    this.captionEditing = value
  }

  patchPosition() {
    this.storePatch({
      position: [this.position.x, this.position.y],
    })
  }

  setPosition(position: Point) {
    this.position = position
  }

  setCaption(value: string) {
    const caption = value.trim()

    this.storePatch({
      caption,
    })

    this.caption = caption
  }

  clone() {
    const clone = new CalcMember(this.doc, generateMemberId(), new Point(this.position), this.expression.clone())
    clone.setCaption(this.caption)
    return clone
  }

  private async storePatch(patch: CalcMemberPatch): Promise<void> {
    return calcPatchStorage.patchMember(this.doc.id, this.id, patch)
  }
}
